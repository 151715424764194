@import "../../../../styles/variables.scss";

.profile .custom-one-container.without-text {
  .image {
    margin: 0;
    width: 100% !important;
    max-width: none !important;
    aspect-ratio: 21/9 !important;
    border-radius: 0 !important;
  }
}

.profile .custom-one-container.without-image {
  .info {
    .title {
      text-align: center;
      line-height: 1.5;
    }
  }
  @include desktop {
    .info {
      width: 100%;
      gap: 100px;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      .title {
        width: 100%;
      }
      .info-descriptions {
        flex-grow: 1;
      }
    }
  }
}
