@import "../../../../styles/variables.scss";

.profile .intro-container {
  width: 100%;
  padding: 64px 0;
  max-width: none;
  .intro-content {
    width: 100%;
    position: relative;
    .intro-text {
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 32px;
      color: white;
      letter-spacing: -0.3;
    }
    video {
      aspect-ratio: 16/9;
      background-color: #141414;
    }
    .play-btn {
      position: absolute;
      background-color: transparent;
      padding: 0;
    }
  }
  @include tablet {
    padding: 80px 0;
    .intro-content {
      .intro-text {
        font-size: 48px;
      }
    }
  }
  @include desktop {
    padding: 120px 0;
    .intro-content {
      video {
      }
      .intro-text {
        font-size: 64px;
      }
    }
  }
}
