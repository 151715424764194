@import "../../../../../../styles/variables.scss";

//Default theme
.profile .contact-container.default.rimal {
  background-color: #011548;
  .contact-content {
    .title {
      color: #fff;
    }
    .info-items {
      .info-item {
        .label,
        .info {
          color: #fff;
        }
      }
    }
  }
}
