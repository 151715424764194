.section-overlay-container {
    position: relative;
    width: 100%;
    justify-content: flex-start;
    transition: opacity 0.3s ease;
}

.overlay {
    position: relative;
    border:4px solid #6B43EF;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background: rgba(0, 0, 0, 0.6);
    /* Darker shade for better visibility */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 10;
    /* Ensures the overlay is on top */
    transition: opacity 0.3s ease;
}

.edit-button {
    position: absolute;
    top:-48px;
    background: #fafafa;
    padding: 4px 6px 0px 12px;
    // background: linear-gradient(45deg, #ff6f61, #ff3d00);
    /* Vibrant gradient */
    color: #2318DD;
    border: none;
    height: fit-content;
    /* Increased padding for larger button */
    // border-radius: 4px;
    border: 3px solid #6B43EF;
    cursor: pointer;
    font-size: 18px;
    /* Larger font size for better readability */
    font-weight: bold;
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    /* Shadow for depth */
    transition: background 0.3s ease, transform 0.3s ease;
    /* Smooth transitions */

    @media screen and (min-width: 768px) {
         padding: 8px 10px 4px 16px;
         top:-48px;      
    }

    @media screen and (min-width:992px){
        top:-24px;     
    }
}

.edit-button::before {
    content: url('../../assets/edit-icon.svg');
}

