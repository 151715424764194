@import "../../../../../../styles/variables.scss";

//Default theme
.profile .social-container.default {
  .title {
    text-align: center;
    margin-bottom: 24px;
    color: #000;
    font-weight: 600;
    max-width: 1090px;
  }
  .social-items {
    gap: 24px;
    justify-content: center;
    .social-item {
      height: 48px;
      aspect-ratio: 1;
      border-radius: 48px;
      justify-content: center;
    }
  }
  @include tablet {
    .title {
      margin-bottom: 32px;
    }
    .social-items {
      .social-item {
        height: 64px;
        svg {
          transform: scale(1.3);
        }
      }
    }
  }
  @include desktop {
    .title {
      margin-bottom: 32px;
    }
    .social-items {
      .social-item {
        height: 64px;
        svg {
          transform: scale(1.5);
        }
      }
    }
  }
  @include large-desktop {
    .title {
      margin-bottom: 32px;
    }
    .social-items {
      .social-item {
        height: 64px;
        svg {
          transform: scale(1.5);
        }
      }
    }
  }
}
