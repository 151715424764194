@import "../../../../../../styles/variables.scss";

.feature-1 > img {
  max-height: 15em;
}

.feature > img {
  max-height: 15em;
}

//Default theme
.profile .custom-three-container.default {
  gap: 48px;
  width: 100%;
  .custom-three-content {
    width: 100%;
    gap: 32px;
    // max-width: 1440px;
  }
  .text {
    width: 100%;
    text-align: center;
    align-items: center;
    gap: 8px;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    .header {
      color: #2a2a2a;
      font-family: Inter;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -1px;
      max-width: 533px;
    }
    .description {
      color: #616161;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 1.5;
      letter-spacing: -0.5px;
      max-width: 533px;
    }
    @include tablet {
      margin-bottom: 48px;
      gap: 16px;
      .header {
        font-size: 32px;
      }
      .description {
        font-size: 18px;
      }
    }
    @include desktop {
      // margin-bottom: 64px;
      // gap: 24px;
      .header {
        font-size: 40px;
      }
    }
    @include tablet {
      .header {
        // font-size: 32px;
      }
    }
  }
  .custom-three-swiper {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .swiper-slide {
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      height: auto;
    }

    .swiper-slide.without-img {
      height: auto;
    }
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
      border-radius: 100px;
      height: 60px;
      width: 60px;
      background-color: #b69e42;
      &::after {
        font-size: 20px;
        font-weight: 700;
        color: #fff;
      }
    }
    .swiper-button-prev {
      left: 40px;
      padding-right: 5px;
    }
    .swiper-button-next {
      right: 40px;
      padding-left: 5px;
    }
  }
  .custom-items {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: start;
    gap: 48px;
    @include tablet {
      grid-template-columns: 1fr 1fr;
    }
    @include desktop {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .custom-item {
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 16px;
    .image {
      width: 100%;
      aspect-ratio: 1;
      overflow: hidden;
      display: flex;
      align-items: start;
      justify-content: start;
      img {
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
      }
    }
    .item-info {
      gap: 8px;
      padding: 0 8px;
      .title {
        color: #000;
        text-align: center;
        font-size: 24px;
        font-weight: 600;
        margin: 0;
        letter-spacing: -0.5px;
      }
      p {
        color: #000;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 150%; /* 27px */
        letter-spacing: -0.5px;
      }
    }
  }
  .custom-item.without-img {
    background-color: #fff;
    align-items: start;
    justify-content: start;
    padding: 24px 24px 40px 24px;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    border-radius: 16px;
    height: 100%;
    @include tablet {
      padding: 32px 24px;
    }
    @include desktop {
      padding: 64px 32px;
    }
    .item-info {
      align-items: start;
      justify-content: start;
      padding: 0;
      gap: 24px;
      .title {
        color: #18181c;
        font-weight: 700;
        width: 100%;
        font-size: 24px;
        text-align: left;
      }
      p {
        font-size: 18px;
        color: #18181c;
        font-weight: 500;
        text-align: left;
      }
    }
  }

  @include tablet {
    .custom-three-swiper {
      .swiper-pagination {
        display: none;
      }
      .swiper-button-next,
      .swiper-button-prev {
        display: flex;
      }
      .swiper-slide {
        .custom-item {
          align-items: center;
          gap: 24px;
          .item-info {
            .title {
              font-size: 32px;
              letter-spacing: -1px;
            }
            .description {
              font-size: 18px;

              letter-spacing: -0.4px;
            }
          }
        }
      }
      .swiper-slide.without-img {
        padding: 64px 32px;
        border-radius: 16px;
      }
    }
  }
  @include desktop {
    .swiper-slide {
      grid-template-columns: 1fr 1fr 1fr;
      .custom-item {
        justify-content: space-between;
        gap: 24px;
      }
    }
  }
}
