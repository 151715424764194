@import "../../../../../../styles/variables.scss";

.profile .update-container.intenxfitness {
  padding-top: 64px;
  padding-bottom: 64px;
  background-color: #141414;
  max-width: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  .update-items {
    max-width: 1440px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 80px;
    .update-card {
      width: 100%;
      a {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 32px;
        align-items: center;
        .update-text {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: start;
          gap: 8px;
          .update-title {
            font-size: 28px;
            color: #fff;
            letter-spacing: -0.8px;
            line-height: 1.5;
            font-weight: 600;
          }
          .update-description {
            font-size: 16px;
            color: #fff;
            letter-spacing: -0.3px;
            line-height: 1.5;
            font-weight: 400;
          }
        }
        .update-image {
          width: 100%;
          overflow: hidden;
          .nikesh-update-swiper {
            width: 100%;
            overflow: hidden;
            .swiper-slide {
              text-align: center;
              font-size: 18px;
              display: flex;
              justify-content: center;
              align-items: center;
              .image {
                aspect-ratio: 16/10;
                overflow: hidden;
                img {
                  min-width: 100%;
                  min-height: 100%;
                  object-fit: cover;
                }
              }
            }
            .swiper-pagination-bullet-active {
              background-color: #000;
            }
          }
        }
      }
    }
  }
  @include tablet {
    padding-top: 80px;
    padding-bottom: 80px;
    .update-items {
      gap: 150px;
      .update-card {
        a {
          gap: 64px;
          .update-text {
            flex-direction: row;
            align-items: start;
            justify-content: space-between;
            gap: 150px;
            flex-wrap: nowrap;
            .update-title {
              font-size: 36px;
              letter-spacing: -1px;
              max-width: 600px;
              width: 100%;
            }
            .update-description {
              flex-grow: 1;
              font-size: 16px;
              letter-spacing: -0.3px;
              max-width: 600px;
            }
          }
        }
      }
    }
  }
  @include desktop {
    padding-top: 150px;
    padding-bottom: 150px;
    .update-items {
      gap: 300px;
      .update-card {
        a {
          gap: 100px;
          .update-text {
            .update-title {
              font-size: 48px;
              letter-spacing: -1px;
              max-width: 533px;
            }
            .update-description {
              font-size: 18px;
              letter-spacing: -0.3px;
              max-width: 533px;
            }
          }
        }
      }
    }
  }
}
