@import "../../../../../../styles/variables.scss";

.profile .hero-centered-container.default.somebodysgun {
  .hero-centered-content {
    .info {
      .title {
        color: #1b90dd;
      }
      .description {
        color: #000;
      }
    }
  }
}
