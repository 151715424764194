@import "../../../../../../styles/variables.scss";

.profile .client-container.default {
  // padding-top: 64px;
  // padding-bottom: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .client-content {
    // max-width: 1440px;
    .title {
      text-align: left;
    }
    .client-images {
      display: grid;
      // grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
      grid-template-columns: 1fr 1fr;
      overflow: hidden;
      border-radius: 8px;
      border: solid 1px #e5e5e5;
      @include tablet {
        grid-template-columns: 1fr 1fr 1fr;
      }
      @include desktop {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
      @include large-desktop {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      }
      .client-image {
        aspect-ratio: 1;
        padding: 32px;
        margin: -1px;
        border-right: solid 1px #e5e5e5;
        border-bottom: solid 1px #e5e5e5;
        display: flex;
        align-items: center;
        justify-content: center;
        > img {
          max-width: 100%;
          max-height: 100%;
          // flex-shrink: 0;
          object-fit: cover;
        }
      }
    }
  }
  @include tablet {
    // padding-top: 80px;
    // padding-bottom: 80px;
  }
  @include desktop {
    // padding-top: 120px;
    // padding-bottom: 120px;
  }
  @include large-desktop {
    // padding-top: 150px;
    // padding-bottom: 150px;
  }
}
