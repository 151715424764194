@import "../../../../../../styles/variables.scss";

.profile .contact-container.standard {
  padding-top: 64px;
  padding-bottom: 64px;
  margin: 0;
  .contact-content {
    width: 100%;
    align-items: flex-start;
    gap: 24px;
    .title {
      align-self: flex-start;
      color: #616161;
      font-family: Inter;
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -1.5px;
    }
    .info-items {
      width: 100%;
      .info-item {
        align-items: start;
        width: 100%;
        padding: 16px 0;
        border-bottom: 1px solid #e5e5e5;
        .label {
          color: #616161;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 1.5;
          letter-spacing: -0.28px;
          margin-bottom: 8px;
        }
        .info {
          color: #a12a5d;
          font-family: Inter;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -1px;
          text-transform: uppercase;
          max-width: fit-content;
          word-break: break-all;
        }
      }
      .info-item:last-child {
        border: none;
      }
    }
  }
  @include tablet {
    display: flex;
    .contact-content {
      gap: 48px;
      .title {
        width: calc(40% - 56px);
      }
      .info-items {
        width: 60%;
        gap: 40px;
        padding: 0;
        .info-item {
          border: none;
          padding: 0;
          .label {
            color: #616161;
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.54px;
          }
          .info {
          }
        }
      }
    }
  }
  @include desktop {
    padding-top: 130px;
    padding-bottom: 130px;
    display: flex;
    .contact-content {
      gap: 56px;
      .title {
        width: calc(35% - 56px);
      }
      .info-items {
        width: 65%;
        gap: 40px;
        padding: 0;
        .info-item {
          border: none;
          padding: 0;

          .label {
            color: #616161;
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.54px;
          }
          .info {
          }
        }
      }
    }
  }

  @include large-desktop {
    display: flex;
    .contact-content {
      gap: 56px;
      .title {
        width: calc(40% - 56px);
      }
      .info-items {
        width: 60%;
        gap: 40px;
        padding: 0;
        .info-item {
          border: none;
          padding: 0;
          .label {
            color: #616161;
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.54px;
          }
          .info {
          }
        }
      }
    }
  }
}
