* {
  font-family: var(--font-family) !important;
  /* font-feature-settings: var(--font-feature-settings, normal) !important; */
}
.onboarding-container {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  min-height: 100vh !important;
  padding: 18px;
  background-image: url('../../../assets/background.png') !important;
  background-size: cover !important;
  backdrop-filter: blur(10px) !important;
  position: relative !important;
}

.lottie-background {
  position: fixed !important; 
  top: 0 !important;
  left: 0 !important;
  width: 100vw !important; 
  height: 100vh !important; 
  z-index: -1 !important; 
  pointer-events: none !important;
  filter: blur(10px) !important;
}

.p-steps .p-steps-item:before {
    content: " " !important;
    border-top: 1px solid #dee2e6 !important;
    width: 100% !important;
    top: 28px !important;
    left: 0 !important;
    display: block !important;
    position: absolute !important;
    margin-top: -1rem !important;
}

.p-component {
  font-family: var(--font-family) !important;
  font-feature-settings: var(--font-feature-settings, normal) !important;
  /* font-size: 1rem !important; */
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  margin-top: 0.5rem !important;
}

.p-card .p-card-body {
  padding: 0rem 1rem 1rem 1rem !important;
}

.p-card .p-card-content {
  padding: .5rem 0 1rem 0 !important;
}

.p-button .p-button-icon-left {
  margin-right: 0.5rem !important;
}
.input-text::placeholder {
  font-weight: 300 !important;
  font-family: var(--font-family) !important;
}
.generate-button {
  font-size: 18px !important;
  border-radius: 4px !important;
  background-color: #2318DD !important;
  font-weight: 600 !important;
  color: #fafafa !important;
  margin: 0 !important;
}

@media (max-height: 639px),
(max-width: 619px) {
  .steps-container {
    display: none !important;
  }
}

.p-steps .p-steps-item .p-menuitem-link {
  background: transparent !important;
}
.p-steps-title {
  color: #fafafa !important;
}
.p-highlight .p-steps-number {
  background: #2318DD !important;
  color: #fafafa !important;
  font-weight: 600 !important;
  border: none !important;
  font-size: 16px !important;
}
.p-steps-title {
  font-weight: 500 !important;
  font-size: 17px !important;
}
.p-highlight .p-steps-title {
  color: #fafafa !important;
  font-weight: 600 !important;
  font-size: 18px !important;
}

.logo {
  position: absolute !important;
  top: 20px !important;
  left: 20px !important;
  width: 120px !important;
  height: auto !important;
}

.steps-container {
  width: 100% !important;
  max-width: 500px !important;
  position: absolute !important;
  top: 50px !important;
}

.onboarding-card {
  width: 100% !important;
  max-width: 480px !important;
  max-height: 540px !important;
  height: fit-content !important;
  padding: 20px !important;
  background-color: #fafafa !important;
  border-radius: 8px !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  transition: max-height 0.8s ease-in-out !important; 
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}

.button-container {
  margin-top: 20px !important;
  display: flex !important;
  justify-content: space-between !important;
}
.p-button-text {
  color: #02062c !important;
  height: fit-content !important;
  width: 100% !important;
  text-align: left !important;
  padding:14px 28px !important;
  cursor:pointer;
  margin: 0 !important;
}

.p-button-text:hover {
  background:#E9E4FB !important;
  font-weight: 500 !important;
}

.p-card-content {
    position: relative !important;
    padding-top: 0 !important;
}
.p-card-body {
  padding-top: .7rem !important;
}

.pi-arrow-left:before {
  content: url('../../../assets/arrow-left.svg') !important;

}
.back-button:hover .pi-arrow-left:before {
  content: url('../../../assets/arrow-left-hover.svg') !important;
}

.pi-map-marker:before  {
  content: url('../../../assets/map-pin.svg');
  position: absolute !important;
  top: -3px !important;
}


@media (max-width: 600px) {

  .steps-container,
  .onboarding-card {
    max-width: 90% !important;
  }
}

.onboarding-container .title {
  font-weight: 700 !important;
  font-size: 1.65rem !important;
  text-align: left;
  margin-top: 8px !important;
  margin-bottom: 18px !important;
  color: #02062c !important;
}

.full-width-button {
  width: 100% !important;
  background-color: #007ad9 !important;
  border-color: #007ad9 !important;
}

.full-width-input {
  width: 100% !important;
  margin-bottom: 1rem !important;
}

.back-button {
  width: fit-content !important;  
  padding: 0px 6px 6px 0 !important;
  margin-bottom: 12px !important;
  outline: none !important;
}
.back-button .p-button-label {
  font-size: 20px !important;
  margin-bottom: 2px !important;
  font-weight: 500 !important;
}
.back-button .p-button-icon {
  font-size: 18px !important;
}

.back-button:hover {
  background: none !important;
  color: #2318DD !important;
}
.back-button:focus {
  box-shadow: none !important;
}

.next-button {
  width: 100% !important;
  background-color: #2318DD !important;
  padding: 18px 28px !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  border-radius: 4px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: #fafafa !important;
}
.next-button:disabled {
  background-color: #adabd2 !important; 
  color: #1d1833 !important; 
  cursor: not-allowed !important; 
  opacity: 0.7 !important;
  border:none !important;
}

.full-width-input {
  width: 100% !important;
  margin-bottom: 4px !important;
  display: flex !important;
  align-items: center !important;
}

.input-text-full {
  width: 100% !important;
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
  padding: 28px 0 28px 16px !important;
}
.input-text-full::placeholder {
  font-family: var(--font-family) !important;
  font-weight: 500 !important;
}
.input-text-full:focus {
  box-shadow: none !important;
  border: 2px solid #2318DD !important;
}
.location-input {
  padding-left: 48px !important;
}
/* .location-input {
  font-weight: 600 !important;
  color: #02062c !important

} */

.p-inputicon {
  color: #666 !important;
  margin-right: 8px !important;
}
.p-input-icon {
  top: 21px !important;
}

.tag-container {
  position: absolute !important;
  z-index: 999 !important;
  width: 100% !important;
  background: #ffffff !important;
  flex-direction: column !important;
  display: flex !important;
  transition: width 0.5s ease-in-out !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  border-radius: 4px !important;
  max-height: 280px;
  overflow: scroll;
}

.tag-container .p-button {
  flex-shrink: 0 !important;
  white-space: nowrap !important;
}
.p-button:focus {
  box-shadow: none !important;
}

/* Container styling */
.autocomplete-dropdown-container {
  width: 100% !important;
  background-color: #ffffff !important;
  padding: 16px 0 !important;
  z-index: 999 !important;
  position: absolute !important;
  overflow-y: scroll !important;
  max-height: 300px !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  border-radius: 4px !important;
}

/* Hide border and shadow when the container is empty */
.autocomplete-dropdown-container:empty {
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.suggestion-item, .suggestion-item-active {
  width: 100% !important;
  padding:14px !important;
  font-size: 16px !important;
  color: #272626 !important;
  margin-bottom: 8px !important;
  transition: background-color 0.3s, color 0.3s !important;
}

.suggestion-item:hover,
.suggestion-item-active {
  background:#E9E4FB !important;
  cursor: pointer !important;
  color:#02062c !important;
  font-weight: 500 !important;
}

.suggestion-item:last-child, .suggestion-item-active:last-child {
  margin-bottom: 0 !important;
}

/* Active state when navigating with arrow keys */
.suggestion-item[aria-selected="true"] {
  background-color: #e0e0e0 !important;
  color: #000 !important;
}

/* Optional: Improve mobile touch experience */
@media (max-width: 600px) {
  .suggestion-item {
    font-size: 18px !important;
    padding: 12px !important;
  }
}

@media screen and (min-width: 1200px ) {
  .onboarding-container .title  {
    font-size: 28px !important;
  }
}