@import "../../../../../../styles/variables.scss";

.profile .hero-centered-container.default {
  width: 100%;
  // padding-top: 64px;
  // padding-bottom: 64px;
  align-items: center;
  text-align: center;
  .hero-centered-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    width: 100%;
    .info {
      width: 100%;
      padding-left: 16px;
      padding-right: 16px;
      gap: 32px;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 1090px;
    }
    .title {
      color: #000;
      text-align: center;
      font-size: 32px;
      font-style: normal;
      line-height: 1.2; /* 48px */
      letter-spacing: -1px;
      margin-bottom: 5px;
    }
    .description {
      color: #606060;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 1.5; /* 157.143% */
      letter-spacing: -0.28px;
      margin-bottom: 16px;
    }
    .image {
      width: 100%;
      aspect-ratio: 1;
      overflow: hidden;
      justify-content: center;
      max-width: 1400px;
      margin-top:50px;
    }
  }
  @include tablet {
    // padding-top: 80px;
    // padding-bottom: 80px;
    .hero-centered-content {
      .title {
        font-size: 48px;
        letter-spacing: -1.5px;
      }
      .description {
        font-size: 20px;
        margin-bottom: 24px;
      }
      .image {
        aspect-ratio: 16/9;
      }
    }
  }
  @include desktop {
    // padding-top: 120px;
    // padding-bottom: 120px;
    .hero-centered-content {
      .image {
        aspect-ratio: 21/9;
      }
    }
  }
}
