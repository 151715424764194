@import "../../../styles/variables.scss";

/* main.scss */

/* Container chính bao phủ toàn bộ màn hình */
.container-main-payment {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    height: 100vh -71px;
}

/* Tiêu đề chính */
.title {
    margin-top: 60px;
    margin-bottom: 40px;
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    line-height: 38.72px;
    color: black;
}

/* Phần nội dung chính */
.main-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100vw;
}

/* Form chính bên trái */
.form-container {
    background-color: white;
    border-radius: 16px;
    box-shadow: 0px 4px 50px 0px #0000001A;
    padding: 32px;
    width: 581px;
    height: fit-content;
}

/* Trường email */
.email-section {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.email-label {
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    color: black;
    display: block;
}

.email-input {
    width: 100%;
    border: 1px solid #c4c4c4;
    border-radius: 8px;
    padding: 10px 20px 14px 20px;
    margin: 16px 0;
    box-shadow: 0px 4px 8px 0px #00000014;
}

/* Trạng thái email */
.email-status {
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    color: #616161;
    // margin-bottom: 40px;
}

.email-connected-icon {
    width: 20px;
    height: 20px;
    margin-right: 4px;
    flex-shrink: 0;
}

.email-connected-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 19.6px;
    color: #32A071;
    margin-bottom: 25px;
}


.email-error-icon {
    width: 20px;
    height: 20px;
    margin-right: 4px;
    flex-shrink: 0;
}

.email-error-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 19.6px;
    color: #616161;
}

.email-error-text span {
    color: #FF3A44;
}

/* Phần tóm tắt đơn hàng */
.order-summary {
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin: 32px 20px;
    width: fit-content;
}

.order-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 19.2px;
    color: black;
    margin-bottom: 16px;
}

.order-item {
    padding: 20px;
    border-radius: 16px;
    border: 1px solid #C4C4C4;
    background-color: white;
    margin-bottom: 32px;
}

.order-feature-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #0B86E7;
    margin-bottom: 8px;
}

.order-feature-item {
    font-weight: 600;
    font-size: 14px;
    line-height: 25.2px;
    color: black;
    margin-bottom: 4px;
}

.order-price-summary {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    font-weight: 500;
    font-size: 18px;
    line-height: 21.78px;
    color: black;
    margin-bottom: 24px;
}

.order-total {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 500;
    font-size: 20px;
    line-height: 24.2px;
    color: black;
    margin-top: 24px;
    margin-bottom: 32px;
}


.payment-button {
    cursor: pointer;
    background-color: #0B86E7;
    padding: 16px 0;
    color: white;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.36px;
    user-select: none;
    margin: 0 24px 32px 24px;
    justify-content: center;
    display: flex;
    width: 90%;
}

.payment-button.disabled {
    background-color: #e5e5e5;
    color: #616161;
}

/* Bảo mật và hoàn tiền */
.security-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.security-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 32px;
}

.security-icon {
    width: 36px;
    height: 38px;
    margin-right: 8px;
}

.security-text {
    font-weight: 700;
    font-size: 16px;
    line-height: 19.36px;
    color: black;
}

/* Chính sách và điều khoản */
.policy-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 14.6px;
    text-align: center;
    margin-bottom: 8px;
}

.policy-footer {
    font-weight: 500;
    font-size: 14px;
    line-height: 19.2px;
    color: black;
    text-align: center;
    margin-bottom: 40px;
}