
.signin-page {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: #fafafa;
    gap: 32px;
    padding-top: 120px !important;
}
.signin-page .google-signin-button {
    background: black !important;
    color:#fafafa;
    font-size: 18px;
    font-weight: 600;
    border-radius: 4px;
    border: 2px solid black !important;
    padding: 12px 24px !important;
    margin: 0 !important;
}
.signin-page .google-signin-button:hover {
    background:#fafafa !important;
    color:black;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.signin-title {
    font-weight: 600;
    font-size: 24px !important;
    margin: 0 !important;
}
.pi-google::before {
    content: url('../../../assets/flat-color-icons_google.png');
    display: inline-block !important;
}

.left-col {
    gap: 16px !important;
}
.right-col  {
    width: 100%;
}
.right-col img {
    margin-left: 16px;
    width: 100%;
    height: 100%;
}
.back-btn {
    display: flex;
    align-items: center;
    top: 3rem;
    position: absolute;
    left: 1rem;
    width: fit-content;
    font-size: 1.25rem;
    padding: 4px 12px 4px 8px!important;
    border: 2px solid rgb(209, 207, 220) !important;
    border-radius:2px !important;
}
.back-btn::before {
    content: url('../../../assets/chevrons-left.svg');
    display: block;
    top: 4px !important;
    position: relative;
}

.information {
    font-size: 18px !important;
    text-align: center;
}
.information a {
    color: #2318DD;
}

.logo-img {
    width:120px;
    margin-left: 0 !important;
    margin-bottom: 24px !important;
}


@media screen and (min-width: 768px){

    .right-col  {
        width: 60%;
    }
    .logo-img {
        /* position: absolute; */
        /* top:40px; */
        margin-top: -200px !important;
    }
}

@media screen and (min-width: 1200px) {

    .signin-title {
        font-size: 44px !important;
    }
    .left-col {
        gap: 32px !important;
    }
    .signin-page {
        gap: 64px;
    }
    .signin-page .google-signin-button {
        padding: 18px 80px !important;
        font-size: 22px !important;
    }

    .back-btn {
        top: 4rem;
        position: absolute;
        left: 4rem;
        width: fit-content;
    }
}