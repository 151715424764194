@import "../../../../../../styles/variables.scss";

.profile .custom-one-container.default.rimal {
  background-color: #011548;
  .custom-one-content {
    .info {
      .title {
        color: #fff;
      }

      .description {
        color: #fff;
      }
    }
  }
}
