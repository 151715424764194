@import "../../../../../../styles/variables.scss";

.profile .client-container.other.somebodysgun {
  background-color: #1b90dd;
  padding-top: 24px;
  padding-bottom: 24px;
  .client-content {
    .client-images {
      .client-swiper {
        padding: 0;
        .swiper-slide {
          .client-image {
            aspect-ratio: 16/9;
            padding: 48px;
            background-color: #fff;
            border-radius: 14px;
          }
        }
      }
    }
  }
}
