@import "../../../../../../styles/variables.scss";

//Julia base
.profile .client-container.standard {
  width: 100%;
  background-color: #fde2e2;
  padding-top: 64px;
  padding-bottom: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  .client-content {
    max-width: 1920px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .title {
      color: #a12a5d;
      font-family: Inter;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -1.5px;
      margin-bottom: 48px;
    }
    .client-images {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      max-width: 1200px;
      border-radius: 8px;
      gap: 36px;
      .client-image {
        height: auto;
        width: 100px;
      }
      @include desktop {
        column-gap: 64px;
        row-gap: 48px;
        .client-image {
          width: 141px;
        }
      }
      @include large-desktop;
    }
  }

  @include tablet {
  }
  @include desktop {
    padding-top: 130px;
    padding-bottom: 130px;
  }
  @include large-desktop {
  }
}
