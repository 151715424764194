@import "../../../../styles/variables.scss";

.profile .map-container {
  padding-left: 0;
  padding-right: 0;
  max-width: none;

  .map {
    max-height: 500px;
    max-width: 700px;
  }

  .description {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: -0.03em;
    text-align: center;
    color: #616161;
    max-width: 500px;
  }
}