@import "../../../../../../styles/variables.scss";

.profile .hero-centered-container.standard {
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
  align-items: center;
  .hero-centered-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    width: 100%;
    .info {
      padding-left: 16px;
      padding-right: 16px;
    }
    .title {
      color: #000;
      text-align: center;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%; /* 48px */
      letter-spacing: -2px;
      margin-bottom: 5px;
    }
    .description {
      color: #606060;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5; /* 157.143% */
      letter-spacing: -0.28px;
      margin-bottom: 16px;
    }
    .image {
      aspect-ratio: 1;
      overflow: hidden;
      justify-content: center;
      max-width: 1400px;
    }
  }
  @include tablet {
    margin-top: 64px;
    margin-bottom: 64px;
    .hero-centered-content {
      .title {
        font-size: 48px;
        margin-bottom: 10px;
      }
      .description {
        font-size: 16px;
        margin-bottom: 24px;
      }
      .image {
        aspect-ratio: 16/9;
      }
    }
  }
  @include desktop {
    margin-top: 80px;
    margin-bottom: 80px;
    .hero-centered-content {
      .title {
        font-size: 80px;
        margin-bottom: 24px;
        letter-spacing: -4px;
      }
      .description {
        font-size: 21px;
        margin-bottom: 64px;
      }
      .image {
        aspect-ratio: 21/9;
      }
    }
  }
}
