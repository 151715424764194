@import "../../styles/variables.scss";

.full-screen-img {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  > img {
    min-width: 100%;
    min-height: 100%;
    // flex-shrink: 0;
    object-fit: cover;
  }
}
.full-screen-img_active {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  .full-screen-img_active-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba($color: #000000, $alpha: 0.8);
  }
  button {
    position: absolute;
    top: 36px;
    right: 16px;
    z-index: 101;
  }
  .full-screen-img-container {
    max-width: 100vw;
    max-height: 100vh;
    height: auto;
    width: auto;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    > img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      object-fit: cover;
      z-index: 100;
    }
  }
  @include tablet {
    .full-screen-img-container {
      // width: 80%;
      // height: 80%;
    }
  }
  @include desktop {
    .full-screen-img-container {
      // width: 70%;
      // height: 70%;
    }
  }
  @include large-desktop {
    .full-screen-img-container {
      // width: 70%;
      // height: 70%;
    }
  }
}
