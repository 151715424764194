@import "../../../../styles/variables.scss";

//Other theme
.profile .custom-three-container.other {
  margin: 80px 0;
  .custom-items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 80px;
    column-gap: 64px;
    .custom-item {
      width: 100%;
      flex-wrap: nowrap;
      column-gap: 32px;
      .image {
        aspect-ratio: 1;
        overflow: hidden;
        max-width: 280px;
      }
      .item-info {
        flex-grow: 1;
        row-gap: 16px;
        align-items: start;

        .title {
          color: #151414;
          font-family: Inter;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -1px;
          margin-bottom: 0;
        }
        .description {
          color: #000;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 1.3;
          letter-spacing: -0.5px;
        }
      }
    }
  }
}
