@import "../../../../../../styles/variables.scss";

.profile .custom-one-container.default {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding-top: 64px;
  padding-bottom: 64px;
  .custom-one-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 24px;
    .image {
      width: 100%;
      aspect-ratio: 16/9;
      overflow: hidden;
    }
    .info {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 100%;
      gap: 8px;
      .title {
        color: #151414;
        font-family: Inter;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: -1.5px;
        margin-bottom: 0;
      }
      .description {
        color: #000;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.5;
        letter-spacing: -0.3px;
      }
    }
  }
  @include tablet {
    padding-top: 80px;
    padding-bottom: 80px;
    .custom-one-content {
      gap: 32px;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      .image {
        width: 100%;
        aspect-ratio: 16/9;
        overflow: hidden;
      }
      .info {
        width: 100%;
        gap: 16px;
        .title {
          font-size: 36px;
        }
      }
    }
  }
  @include desktop {
    padding-top: 150px;
    padding-bottom: 150px;
    .custom-one-content {
      gap: 100px;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;
      .image {
        width: 100%;
        aspect-ratio: 1;
        max-width: 550px;
      }
    }
  }
}
