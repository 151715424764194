@import "../../../../../../styles/variables.scss";

//Default theme
.profile .gallery-container.default.somebodysgun {
  background-color: #1b90dd;
  padding-top: 60px;
  padding-bottom: 60px;
  .gallery-content {
    .gallery-text {
      .header,
      .description {
        color: #fff;
      }
    }
  }
}
