@import "/src/styles/variables.scss";

.container-guest-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    background: linear-gradient(180deg, #E0EFFF 0%, #FAFAFA 100%);
    // padding-bottom: 25px;

    @media screen and (min-width: 768px) {
        // height: 100vh;
    }
}

/* Welcome section */
.content-section-guest-success {
    width: 100vw;
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}

.welcome-content-guest-success {
    width: 100vw;
    height: 100vh !important;
    padding: 24px 0;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap:10px;
    // background: #616161;

    @include mobile {
        padding: 24px 24px 32px 24px;

    }
}

/* What's next card */
.whats-next-card-guest-success {
    background-color: white;
    border-radius: 16px;
    padding: 32px 24px;
    width: 500px;
    display:flex;
    flex-direction: column;
    gap:16px;

    @include mobile {
        width: fit-content;
        // margin: 12px 32px;

    }
}

.experience-more {
    background-color: #ffffff;
    border-radius: 16px;
    margin-bottom: 24px;
    width: 470px;
    align-items: center;

    @include mobile {
        width: fit-content;
        margin: 12px 32px;

    }
}