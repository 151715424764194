@import "../../../../../../styles/variables.scss";

//Default theme
.profile .gallery-container.default {
  width: 100%;
  margin: 0;
  .gallery-content {
    width: 100%;
    .title {
      align-self: self-start;
    }
    .gallery-text {
      width: auto;
      text-align: left;
      align-items: flex-start;
      gap: 8px;
      margin-bottom: 24px;
      .header {
        color: #2a2a2a;
        font-family: Inter;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -1px;
        max-width: 533px;
      }
      .description {
        color: #616161;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.5;
        letter-spacing: -0.5px;
        max-width: 533px;
      }
      @include tablet {
        margin-bottom: 48px;
        gap: 16px;
        .header {
          font-size: 32px;
        }
        .description {
          font-size: 18px;
        }
      }
      @include desktop {
        // margin-bottom: 64px;
        // gap: 24px;
        .header {
          font-size: 40px;
        }
      }
      @include tablet {
        .header {
          // font-size: 32px;
        }
      }
    }
    .gallery-items {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      // grid-template-rows: auto;
      gap: 16px;
      .gallery-item {
        aspect-ratio: 3/2;
        overflow: hidden;
        // margin-bottom: 16px;
      }
      @include tablet {
        grid-template-columns: 1fr 1fr;
      }
      @include desktop {
        grid-template-columns: 1fr 1fr 1fr;
        // column-count: 3;
      }
      @include large-desktop {
        // column-count: 4;
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }
}
