@import "../../../styles/variables.scss";
// @import "../../../styles/stack.scss";
@import "../../../styles/bootstrap.css";
@import "../../../styles/stack-interface.css";
@import "../../../styles/socicon.css";
@import "../../../styles/flickity.css";
@import "../../../styles/iconsmind.css";
@import "../../../styles/jquery.steps.css";
@import "../../../styles/theme.css";
@import "../../../styles/custom.css";

.hasHeadband {
  margin-top: 120px;

  @media screen and (min-width:768px) {
    margin-top: 80px;
  }
}

.profile section {
  // padding: 0 16px;
  // max-width: 1536px;
  width: 100%;
  position: relative;

  // margin-bottom: 80px;
  @include tablet() {
    // padding: 0 24px;
  }

  @include desktop() {
    // padding: 0 48px;
    // margin-bottom: 150px;
  }

  * {
    white-space: pre-line;
    word-break: break-word;
  }
}

.profile.freemium {
  background-color: #000;
}

.profile.justine {
  background-color: #f4f7fa;

  .sections-container {
    & .cta-container:nth-child(3) {
      .cta-content {
        background-color: #dfc593;
      }
    }

    & .cta-container:nth-child(7) {
      .cta-content {
        background-color: #a7c4ca;
      }
    }
  }
}

.profile.yosha {
  .sections-container {
    & .custom-three-container:nth-child(8) {
      .custom-three-content {
        .custom-items {
          .custom-item {
            background-color: #e5c7c9;

            .item-info {
              >h2 {
                color: #cd5d9d;
              }

              >p {
                color: #515151;
              }
            }
          }
        }
      }
    }
  }
}

.profile.intenxfitness {
  .sections-container {

    >*:nth-child(6),
    >*:nth-child(7) {
      background-color: #141414;
      color: white;
    }

    >*:nth-child(7) {
      .custom-one-content {
        .info {
          .info-descriptions {
            .description {
              font-size: 18px;
              font-weight: 400;
            }

            .description:nth-child(1),
            .description:nth-child(4) {
              font-size: 24px;
              font-weight: 700;
            }
          }
        }
      }
    }

    >*:nth-child(8) {
      .text-left-content {
        .info {
          .info-descriptions {
            .description {
              font-size: 18px;
              font-weight: 400;
            }

            .description:nth-child(1),
            .description:nth-child(4) {
              font-size: 24px;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}

.profile.default.somebodysgun {
  background-color: #f1f1f1;

  .sections-container {
    >.custom-three-container {
      background-color: transparent;
    }

    >div:nth-child(1 of .custom-three-container) {
      background-color: #1b90dd;

      .custom-three-content {
        .text {

          .header,
          .description {
            color: #fff;
          }
        }
      }
    }
  }
}

.profile {
  .title {
    color: #2a2a2a;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -1px;
    margin-bottom: 16px;
  }

  .sections-container {
    justify-content: flex-start;
    width: 100%;
    min-height: 100vh;
    justify-content: flex-start;

    >*:first-child {
      margin-top: 0 !important;
    }

    >div:nth-child(2n of .gallery-container) {
      .gallery-content {
        .gallery-text {
          align-self: flex-end;
        }
      }
    }

    >div:nth-child(2n + 1 of .gallery-container) {
      .gallery-content {
        .gallery-text {
          align-self: flex-start;
        }
      }
    }
  }

  @include tablet {
    .title {
      font-size: 24px;
      letter-spacing: -1px;
      margin-bottom: 36px;
    }
  }

  @include desktop {
    .title {
      font-size: 40px;
      letter-spacing: -1.8px;
      margin-bottom: 56px;
    }
  }
}

iframe,
iframe:focus-visible {
  outline: none;
}

.headband {
  position: fixed;
  top: 0;
  z-index: 99999;
  background-color: #fff;
  color: #000;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  /* Center items vertically */
  justify-content: space-between;
  width: 100%;
  border-radius: 0;
  /* Remove rounded corners */
  margin: 0;
  /* Remove margins */
}

.headband img {
  height: 30px;
  /* Adjust height as needed */
  width: auto;
  /* Maintain aspect ratio */
  margin: 0;
  /* Remove margins */
}

.customize-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.customize-button:focus {
  outline: none;
}

.customize-button {
  background-color: #007bff;
  /* Nice blue color */
  color: #fff;
  border: none;
  padding: 6px 14px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  /* Bigger text */
  transition: background-color 0.3s, transform 0.2s;
  white-space: nowrap;
  /* Prevent text wrapping */
  margin: 0;
  /* Remove margins */
}

.customize-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.customize-button:focus {
  outline: none;
}