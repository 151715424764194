@import "../../../../../../styles/variables.scss";

//Standard theme
.profile .hero-container.standard {
  background-color: transparent;
  position: relative;
  display: flex;
  align-items: flex-end;
  margin: 0;
  height: auto;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  max-width: unset;
  padding: 0;
  padding-top: 32px;
  padding-bottom: 32px;
  max-width: none;
  .hero-swiper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding-top: 36px;
    padding-bottom: 36px;
    .swiper-slide {
      .hero-swiper-item {
        aspect-ratio: 16/9;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          min-height: 100%;
          min-width: 100%;
        }
      }
    }
  }
  .hero-text {
    padding-left: 16px;
    padding-right: 16px;
    z-index: 2;
  }
  .hero-overlay {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.1) 25%,
      rgba(0, 0, 0, 0.5) 45%,
      rgba(0, 0, 0, 1) 77%
    );
    z-index: 1;
  }
  .hero-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    .image {
      display: none;
      width: 100%;
      aspect-ratio: 1;
      justify-content: center;
      overflow: hidden;
    }
    .title {
      color: black;
      font-size: 80px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.66px;
      margin: 0;
    }
    .content {
      width: fit-content;
      max-width: 200px;
      align-self: flex-end;
    }
    .header {
      width: 100%;
      color: black;
      font-size: 21px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: -0.32px;
      align-self: flex-end;
    }
    .description {
      width: 100%;
      color: black;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 1.5;
      letter-spacing: -0.32px;
      align-self: flex-end;
    }
    .button.connect-btn {
      width: 100%;
      height: 50px;
      border-radius: 100px;
      background-color: black;
      margin-top: 24px;
      p {
        color: #fff;
        font-feature-settings: "clig" off, "liga" off;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.28px;
      }
    }
  }
  .hero-text {
    max-width: 1536px;
  }

  @include tablet {
    .hero-item {
      padding-left: 24px;
      padding-right: 24px;
      .title {
        font-size: 120px;
      }
      .content {
        max-width: 550px;
      }
      .header {
        font-size: 32px;
      }
      .description {
        font-size: 21px;
      }
      .button.connect-btn {
        width: fit-content;
        height: 54px;
      }
    }
    .hero-text {
      display: flex;
      flex-direction: column;
    }
  }

  @include desktop {
    .hero-item {
      width: 100%;
      padding-left: 48px;
      padding-right: 48px;
      .title {
        font-size: 200px;
      }
      .content {
        max-width: 850px;
      }
      .header {
        font-size: 48px;
      }
      .description {
        font-size: 28px;
        letter-spacing: -0.4px;
      }
      .button.connect-btn {
        width: fit-content;
        height: 54px;
      }
    }
  }
}
