@import "../../../../../styles/variables.scss";

//Standard theme
.profile .process-container.standard {
  background-color: #ccaec3;
  overflow: hidden;
  width: 100%;
  position: relative;
  padding-top: 64px;
  padding-bottom: 64px;
  margin: 0;
  .process-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    display: flex;
    flex-direction: column;
    gap: 48px;
    .title {
      margin: 0;
      color: #fff;
      font-family: Inter;
      font-size: 48px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -1.8px;
    }
    .process-items {
      width: 100%;
      flex-grow: 1;
      gap: 32px;
      > div {
        cursor: pointer;
      }
      .process-item {
        border-radius: 14px;
        gap: 24px;
        width: 100%;
        padding: 32px 24px;
        border: 1px solid #e5e5e5;
        align-items: flex-start;
        .question-item {
          width: 100%;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          .question {
            max-width: 85%;
            color: #fff;
            font-family: Inter;
            font-size: 21px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.3px;
          }
          > svg {
            color: #fff;
          }
        }
        .answer {
          display: none;
          color: #fff;
          font-feature-settings: "clig" off, "liga" off;
          font-family: Inter;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 1.5;
          letter-spacing: -0.3px;
          animation-name: showAnswer;
          animation-duration: 500ms;
        }
      }
      .process-item.show {
        .question-item {
          .question {
            font-weight: 700;
          }
        }
        .answer {
          display: block;
        }
      }
    }
  }
  @include tablet {
    .process-content {
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 48px;
      .title {
        width: 100%;
      }
    }
  }
  @include desktop {
    padding-top: 130px;
    padding-bottom: 130px;
    .title {
      color: #fff;
      font-family: Inter;
      font-size: 48px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -1.8px;
      max-width: 300px;
    }
    .process-content {
      gap: 100px;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }
}

@keyframes showAnswer {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
