@import "../../../../../styles/variables.scss";

.profile .cta-container.default.somebodysgun {
  .cta-content {
    background-color: #18181c;
    .info {
      .title,
      .description {
        color: #fff;
      }
      .link {
        > button {
          background-color: #1b90dd;
          p {
            color: #fff;
          }
        }
      }
    }
  }
}
