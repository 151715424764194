@import "../../../../../../styles/variables.scss";

.profile .custom-three-container.freemium {
  gap: 48px;
  margin-top: 64px;
  margin-bottom: 64px;
  overflow: hidden;
  padding: 0;
  width: 100%;
  .custom-three-content {
    padding: 0;
  }
  .custom-text {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 36px;

    h3 {
      color: #fff;
      text-align: center;
      font-size: 36px;
      font-weight: 700;
    }
  }
  .custom-three-swiper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    .swiper-slide {
      overflow: hidden;
      .custom-item {
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        gap: 24px;
        .image {
          aspect-ratio: 9/10;
          overflow: hidden;
          display: flex;
          align-items: start;
          justify-content: start;
          width: 100%;
          img {
            min-width: 100%;
            min-height: 100%;
            object-fit: cover;
          }
        }
        .item-info {
          gap: 16px;
          padding: 0 16px;
          .title {
            color: #fff;
            text-align: center;
            font-size: 24px;
            font-weight: 600;
            margin: 0;
            letter-spacing: -0.5px;
          }
          p {
            color: #fff;
            text-align: center;
            font-size: 18px;
            font-weight: 400;
            line-height: 150%; /* 27px */
            letter-spacing: -0.5px;
          }
        }
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      border-radius: 100px;
      height: 60px;
      width: 60px;
      background-color: #b69e42;
      &::after {
        font-size: 20px;
        font-weight: 700;
        color: #fff;
      }
    }
    .swiper-button-prev {
      left: 40px;
      padding-right: 5px;
    }
    .swiper-button-next {
      right: 40px;
      padding-left: 5px;
    }
  }
  @include tablet {
    margin-top: 80px;
    margin-bottom: 80px;
    .custom-text {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 36px;

      h3 {
        color: #fff;
        text-align: center;
        font-size: 48px;
        font-weight: 700;
      }
    }
  }
  @include desktop {
    margin-top: 120px;
    margin-bottom: 120px;
  }
}
