@import "../../../../../../styles/variables.scss";

.profile .custom-three-container.yosha {
  gap: 48px;
  margin-top: 64px;
  margin-bottom: 64px;
  overflow: hidden;
  .custom-three-content {
  }
  .custom-text {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    margin-bottom: 24px;
    > h3 {
      color: #515151;
      font-family: Inter;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -1px;
      text-transform: uppercase;
    }
    > p {
      color: #b6b6b6;
      font-family: Inter;
      font-size: 16px;
      font-style: italic;
      font-weight: 600;
      line-height: 1.5;
      letter-spacing: -0.5px;
    }
  }
  .title {
    align-self: flex-start;
  }
  .custom-items {
    display: grid;
    grid-template-columns: 1fr;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 24px;
    .custom-item {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      gap: 16px;
      background-color: #6349a0;
      padding: 24px;

      .item-info {
        align-items: flex-start;
        width: 100%;
        gap: 8px;
        color: #fff;
        .description {
          text-align: center;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 1.5;
          letter-spacing: -0.5px;
          width: 100%;
        }
        .title {
          width: 100%;
          text-align: center;
          font-family: Inter;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.8px;
          margin-bottom: 24px;
          color: #fff;
        }
      }
      .image {
        width: 100%;
        overflow: hidden;
        justify-content: center;
      }
    }
  }

  @include tablet {
    .custom-text {
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      gap: 80px;
      margin-bottom: 48px;
      > h3 {
        font-size: 56px;
        letter-spacing: -1px;
      }
    }
    .custom-items {
      grid-template-columns: 1fr 1fr;
      .custom-item {
        align-items: flex-start;
        justify-content: flex-start;
        gap: 24px;
        height: 100%;
        // .item-info {
        //   .title {
        //     font-size: 36px;
        //     letter-spacing: -0.8px;
        //   }
        //   .description {
        //     font-size: 18px;
        //   }
        // }
      }
    }
  }
  @include desktop {
    margin-top: 100px;
    margin-bottom: 100px;
    .custom-text {
      gap: 180px;
      margin-bottom: 72px;
      > h3 {
        font-size: 80px;
        letter-spacing: -2.5px;
        max-width: 650px;
      }
      > p {
        max-width: 421px;
        font-size: 18px;
      }
    }
    .custom-items {
      grid-template-columns: 1fr 1fr;
      .custom-item {
        justify-content: space-between;
        gap: 36px;
        padding: 40px;
        height: 100%;
        .item-info {
          .title {
            font-weight: 700;
          }
        }
      }
    }
  }
}
