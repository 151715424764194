@import "../../../../../../styles/variables.scss";

//Standard theme
.profile .custom-one-container.standard {
  background-color: #fcf4ec;
  width: 100%;
  .custom-one-content {
    position: relative;
    padding: 0;
    margin: 0;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 24px;
    .image {
      width: 100%;
      height: 100%;
      // aspect-ratio: 1;
      overflow: hidden;
    }
    .info {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 100%;
      gap: 8px;
      padding: 16px;
      // padding-top: 64px;
      // padding-bottom: 64px;
      .title {
        color: white;
        font-family: Inter;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -1px;
        margin-bottom: 0;
        text-align: center;
        text-shadow: 2px 2px 10px #000000;
      }

      .description {
        color: white;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: -0.3px;
        text-align: center;
        text-shadow: 2px 2px 10px #000000;
      }
    }
    .info.without-image {
      padding: 16px;
    }
  }
  @include tablet {
    padding: 0;
    .custom-one-content {
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;
      .image {
        width: 100%;
        max-width: 620px;
        aspect-ratio: 0.9;
        overflow: hidden;
      }
      .info {
        position: relative;
        left: auto;
        top: auto;
        transform: translate(0, 0);
        .title {
          color: #1a1a1a;
          font-family: Inter;
          font-size: 36px;
          font-style: italic;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -1.5px;
          text-align: left;
          text-shadow: none;
        }
        .info-descriptions {
          display: flex;
          flex-direction: column;
          gap: 24px;
          .description {
            color: #616161;
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 1.5;
            letter-spacing: -0.3px;
            text-align: left;
          }
        }
      }
      .info.without-image {
        padding: 48px;
      }
    }
  }
  @include large-desktop {
    .custom-one-content {
      .info {
        .title {
          font-size: 56px;

          letter-spacing: -1.5px;
        }
      }
    }
  }
  &.standard.without-text {
    background-color: transparent;
    .custom-one-content {
      padding-left: 0;
      padding-right: 0;
      width: 100%;
      .image {
        width: 100%;
        aspect-ratio: 21/9;
        max-width: none;
      }
    }
  }
}
