@import "../../../../../../styles/variables.scss";

.profile .bio-container.default {
  width: 100%;
  padding-top: 64px;
  padding-bottom: 64px;

  .bio-content {
    justify-content: space-between;
    flex-wrap: wrap;

    .title {
      width: 100%;
    }

    .description {
      width: 100%;
      flex-grow: 1;
      display: block;
      color: #151414;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 1.5;
      letter-spacing: -0.36px;
    }

    @include tablet {
      align-items: start;
      flex-direction: row;
      gap: 48px;
      flex-wrap: nowrap;

      .title {
        width: 100%;
        max-width: 530px;
      }

      .description {
        flex-grow: 1;
      }
    }

    @include desktop {
      gap: 110px;

      .title {
        font-size: 48px;
      }
    }
  }

  @include tablet {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  @include desktop {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  @include large-desktop {
    padding-top: 150px;
    padding-bottom: 150px;
  }
}