@import "../../../../../../styles/variables.scss";

//Yosha theme
.profile .hero-container.yosha {
  background-color: transparent;
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 560px;
  overflow: hidden;
  background-color: #151414;
  justify-content: center;
  max-width: unset;
  max-height: 900px;
  padding-top: 32px;
  padding-bottom: 32px;
  .hero-image {
    position: absolute;
    top: 0;
    height: 80%;
    left: 0;
    width: 100%;
    z-index: 0;
  }
  .hero-text {
    z-index: 2;
  }
  .hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.1) 25%,
      rgba(0, 0, 0, 0.5) 45%,
      rgba(0, 0, 0, 1) 77%
    );
    z-index: 1;
  }
  .hero-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    .image {
      height: 100%;
      justify-content: center;
      overflow: hidden;
    }
    .title {
      color: white;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: 1.3;
      letter-spacing: -0.66px;
      margin-top: 24px;
      margin-bottom: 16px;
      max-width: 650px;
    }
    .content {
      align-self: flex-end;
      width: fit-content;
    }
    .description {
      color: white;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: -0.32px;
    }
    .button.connect-btn {
      width: 100%;
      height: 50px;
      border-radius: 100px;
      background-color: black;
      margin-top: 24px;
      p {
        color: #fff;
        font-feature-settings: "clig" off, "liga" off;
        font-family: Inter;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.3;
        letter-spacing: -0.28px;
      }
    }
  }
  .hero-text {
    max-width: 1440px;
  }
  @include tablet {
    height: 900px;
    padding-bottom: 56px;
    .hero-item {
      .title {
        color: #fff;
        font-size: 54px;
        letter-spacing: -1.8px;
        width: 100%;
        align-self: flex-start;
      }
      .description {
        color: #fff;
        font-size: 16px;
        line-height: normal;
        letter-spacing: -0.5px;
        width: fit-content;
        align-self: flex-end;
        max-width: 530px;
      }
      .button.connect-btn {
        width: fit-content;
        height: 54px;
      }
    }
    .hero-text {
      display: flex;
      flex-direction: column;
    }
  }

  @include desktop {
    // height: 100vh;
    padding: 100px;
    > img {
      width: 50%;
    }
    .hero-image {
      width: 40%;
    }
    .hero-text {
      width: 55%;
    }
    .hero-item {
      width: 100%;
      .title {
        color: #fff;
        font-family: Inter;
        font-size: 72px;
        font-style: normal;
        font-weight: 700;
        line-height: 1.25; /* 125% */
        letter-spacing: -2px;
      }
      .description {
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        max-width: 700px;
        line-height: 1.5;
        letter-spacing: -0.65px;
        align-self: flex-end;
      }
      .button.connect-btn {
        width: fit-content;
        height: 54px;
      }
    }
  }

  &.without-img {
    background-color: transparent;
    height: auto;
    .hero-overlay {
      background: none;
      background-color: transparent;
    }
    .hero-text {
      padding: 0;
      max-width: 1440px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;
      .title {
        width: 100%;
        color: #151414;
        font-weight: 700;
        font-size: 42px;
        line-height: 1.2;
        letter-spacing: -1px;
        text-align: center;
        max-width: none;
        margin-bottom: 8px;
      }
      .content {
        width: 100%;
        gap: 8px;
      }
      .header {
        width: 100%;
        color: #151414;
        font-weight: 500;
        font-size: 21px;
        line-height: 1.5;
        letter-spacing: -0.3px;
        text-align: center;
        max-width: none;
      }
      .description {
        display: block;
        width: 100%;
        color: #151414;
        font-weight: 400;
        font-size: 18px;
        line-height: 1.5;
        letter-spacing: -0.3px;
        text-align: center;
        max-width: none;
      }

      > * {
        text-align: center;
        margin: 0;
      }
      .button.connect-btn {
        margin-top: 36px;
      }
      @include tablet {
        .title {
          font-size: 48px;
          letter-spacing: -2px;
          margin-bottom: 16px;
        }
        .description {
          font-size: 24px;
          line-height: 1.5;
          letter-spacing: -0.5px;
        }
      }
      @include desktop {
        gap: 0px;
        .title {
          font-size: 72px;
          font-weight: 700;
          letter-spacing: -2px;
          margin-bottom: 24px;
        }
        .description {
          font-size: 24px;
          letter-spacing: -0.3px;
        }
      }
    }
  }
}
