@import "../../../../../styles/variables.scss";

//Default theme
.profile .process-container.default {
  overflow: hidden;
  width: 100%;
  position: relative;
  margin: 0;
  h2 {
    margin-bottom: 2em;
  }
  .process-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    display: flex;
    flex-direction: column;
    gap: 48px;
    .title {
      margin: 0;
    }
    .process-items {
      width: 100%;
      flex-grow: 1;
      gap: 32px;
      .process-item {
        gap: 24px;
        width: 100%;
        padding-bottom: 32px;
        border-bottom: 1px solid #e5e5e5;
        align-items: flex-start;
        .question-item {
          width: 100%;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          .question {
            max-width: 85%;
            font-size: 21px;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.6px;
            margin-bottom: 0;
          }
        }
        .answer {
          display: none;
          color: #616161;
          font-feature-settings: "clig" off, "liga" off;
          font-size: 16px;
          line-height: 1.5;
          letter-spacing: -0.3px;
          animation-name: showAnswer;
          animation-duration: 500ms;
        }
      }
      .process-item.show {
        .question-item {
          .question {
            color: #0468be;
          }
          > svg {
            color: #0468be;
          }
        }
        .answer {
          display: block;
        }
      }
    }
  }
  @include tablet {
    .process-content {
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 48px;
      .title {
        max-width: 350px;
        width: 40%;
      }
    }
  }
  @include desktop {
    .title {
      max-width: 350px;
    }
    .process-content {
      gap: 100px;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }
}

@keyframes showAnswer {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
