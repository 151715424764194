@import "../../../../../../styles/variables.scss";

.profile .update-container.yosha {
  padding: 64px 16px;
  .update-text {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    margin-bottom: 24px;
    > h3 {
      color: #515151;
      font-family: Inter;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -1px;
      text-transform: uppercase;
    }
    > p {
      color: #b6b6b6;
      font-family: Inter;
      font-size: 16px;
      font-style: italic;
      font-weight: 600;
      line-height: 1.5;
      letter-spacing: -0.5px;
    }
  }
  .update-items {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    > a {
      display: block;
      width: 100%;
      height: auto;
      overflow: hidden;
      aspect-ratio: 2/3;
      .update-card {
        width: 100%;
        height: 100%;
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 24px;
        .update-image {
          position: absolute;
          top: 50%;
          left: 50%;
          height: 100%;
          width: 100%;
          transform: translate(-50%, -50%);
          background-color: #1d1d1d;
          z-index: 1;
          > img {
            min-width: 100%;
            min-height: 100%;
            object-fit: cover;
          }
          .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(
              to bottom,
              rgba(0, 0, 0, 1),
              rgba(0, 0, 0, 0.2)
            );
          }
        }
        .update-info {
          z-index: 2;
          gap: 16px;
          .update-title {
            color: #fff;
            font-size: 24px;
            font-weight: 600;
            letter-spacing: -0.6px;
            text-transform: uppercase;
          }
          .update-description {
            color: #fff;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 1.5;
            letter-spacing: -0.6px;
          }
        }
      }
    }
  }
  @include tablet {
    padding: 80px 24px;
    .update-text {
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      gap: 80px;
      margin-bottom: 48px;
      > h3 {
        font-size: 56px;
        letter-spacing: -1px;
      }
    }
    .update-items {
      grid-template-columns: 1fr 1fr;
      gap: 24px;
      .update-card {
        .update-info {
          gap: 24px;
          .update-title {
            font-size: 32px;
            letter-spacing: -1px;
            font-weight: 700;
          }
          .update-description {
            font-weight: 600;
            font-size: 18px;
            letter-spacing: -0.7px;
          }
        }
      }
    }
  }

  @include desktop {
    padding: 150px 48px;
    .update-text {
      gap: 180px;
      margin-bottom: 72px;
      > h3 {
        font-size: 80px;
        letter-spacing: -2.5px;
        max-width: 650px;
      }
      > p {
        max-width: 421px;
        font-size: 18px;
      }
    }
    .update-items {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
}
