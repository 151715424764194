@import "../../../../../../styles/variables.scss";

.profile .contact-container.yosha {
  width: 100%;
  .contact-content {
    padding-top: 64px;
    padding-bottom: 64px;
    width: 100%;
    align-items: flex-start;
    gap: 16px;
    .title {
      align-self: flex-start;
      color: #151414;
      margin-bottom: 0;
    }
    .info-items {
      width: 100%;
      .info-item {
        align-items: start;
        width: 100%;
        padding: 16px 0;
        .label {
          color: #151414;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.5px;
          margin-bottom: 16px;
        }
        .info-list {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
        }
        .info {
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.5px;
          word-break: break-all;
        }
      }
    }
  }
  @include tablet {
    display: flex;
    .contact-content {
      gap: 48px;
      flex-wrap: nowrap;
      .title {
        width: 100%;
        // max-width: 500px;
      }
      .info-items {
        flex-grow: 1;
        gap: 40px;
        padding: 0;
        .info-item {
          border: none;
          padding: 0;
          .label {
            font-size: 18px;
            letter-spacing: -0.8px;
          }
          .info {
            font-size: 24px;
            letter-spacing: -0.96px;
          }
        }
      }
    }
  }
  @include desktop {
    display: flex;
    .contact-content {
      padding-top: 150px;
      padding-bottom: 150px;
      gap: 100px;
      .info-items {
        gap: 40px;
        padding: 0;
        .info-item {
          border: none;
          padding: 0;
          .info {
            font-size: 32px;
            letter-spacing: -0.96px;
          }
        }
      }
    }
  }
}
