@import "../../../../../styles/variables.scss";

.profile .cta-container.default {
    width: 100%;
    margin-top: 32px;
    margin-bottom: 32px;
    padding: 0 16px;

    .cta-content {
        padding: 0;
        height: 100%;
        position: relative;
        width: 100%;
        // background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        overflow: hidden;
        // max-width: none;
        max-width: 1440px;

        .ParallaxBanner {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            z-index: 1;
            overflow: hidden;

            layer {
                min-width: 100%;
                min-height: 100%;
                object-fit: cover;
                opacity: 0.3;
            }
        }

        .info {
            padding: 40px 24px;
            position: relative;
            z-index: 2;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 24px;

            .title {
                color: #000;
                text-align: center;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 1.5;
                letter-spacing: -0.5px;
                margin-bottom: 0;
            }

            .description {
                color: #000;
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 1.5;
                letter-spacing: -0.54px;
            }
        }

        .link {
            z-index: 2;
            margin-top: 32px;

            >button {
                background-color: #fff;
                border-radius: 12px;
                padding: 20px 40px;

                >p {
                    font-size: 16px;
                    letter-spacing: -0.4px;
                    font-weight: 700;
                    color: #000;
                }
            }
        }
    }

    @include tablet {
        margin-top: 64px;
        margin-bottom: 64px;
        padding: 0 24px;

        .cta-content {
            .info {
                padding: 64px;

                .title {
                    font-size: 40px;
                    letter-spacing: -0.8px;
                }

                .description {
                    font-size: 20px;
                }
            }
        }
    }

    @include desktop {
        margin-top: 120px;
        margin-bottom: 120px;
        padding: 0 48px;

        .cta-content {
            .info {
                padding: 80px;
            }
        }
    }

    @include large-desktop {
        .cta-content {
            .info {
                padding: 120px;
            }
        }
    }

    &.without-text {
        aspect-ratio: 1;
        max-height: 600px;

        .cta-content {
            background-color: #000 !important;
            border-radius: 0;
            max-height: 500px;

            .image {
                .full-screen-img {
                    >img {
                        opacity: 0.8;
                    }
                }
            }

            @include desktop {
                max-height: 600px;
            }
        }
    }
}