@import "../../../../../../styles/variables.scss";

//Default theme
.profile .custom-three-container.default.somebodysgun {
  .text {
    .header,
    .description {
      max-width: none;
    }
  }
  .custom-three-swiper {
    .swiper-button-next.swiper-button-disabled,
    .swiper-button-prev.swiper-button-disabled {
      display: none;
    }

    .swiper-button-prev,
    .swiper-button-next {
      background-color: #fff;
      filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
      &::after {
        color: #000;
      }
    }
  }
}
