@import "../../../styles/variables.scss";

.headband {
  justify-content: space-evenly !important;
  flex-wrap: wrap;
  gap: 24px;
  align-content: center;

  h5 {
    margin-bottom: 0 !important;
    order: 3;
    color: #6A7995;
  }

  @media screen and (min-width: 886px) {
    justify-content: space-around !important;

    h5 {
      order: initial;

    }
  }

}

.excerpt {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;

  @media screen and (min-width: 768px) {
    width: 80%;
    -webkit-line-clamp: 4;
  }

}

.no-blog-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  // height: 100vh;
  background-color: #f8f9fc; 
}

.no-blog-card {
  background: white;
  padding: 40px;
  border-radius: 4px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 500px;
  width: 100%;
}

.no-blog-card h2 {
  // font-size: 20px;
  color: #333;
  font-weight: 600;
  line-height: 120% !important;
  margin-bottom: 16px !important;
}

.no-blog-card p {
  font-size: 18px;
  color: #666;
  margin-bottom: 20px;
}


.bottom-bar {
  display: flex;
  align-items: center;
  gap: 8px;
}

.bottom-bar::before {

  @media screen and (min-width:768px) {
    content: url('../../../assets/arrow-left_down.svg');
  }
}

.breadcrumb {
  // padding-left: 10%;
}

.breadcrumb {
  .breadcrumb__item {
    font-size: 16px;
    color: #02062C;

    a {
      color: #6A7995;
      text-decoration: none;
    }

    @media screen and (min-width:992px) {
      font-size: 18px;
    }
  }

  .breadcrumb__item:nth-child(1) {
    margin-right: 10px;
  }

  .breadcrumb__item:nth-child(2) {
    font-weight: 500;
  }

  .breadcrumb__item:nth-child(1)::after {
    position: relative;
    top: 2px;
    left: 2px;
    content: url('../../../assets/arrow-right.svg');
  }

}


.ghost-box .article__body {
  margin-top: 52px;
  display: flex;
  gap: 40px;
  flex-direction: column;

  .section-container {
    display: flex;
    flex-direction: column;
    gap: 24px;

    p {
      color: #02062C !important;
      margin: 0 !important;

      span {
        display: block;
        margin-top: 16px; 
      }
    }
  
    h2 {
      font-weight: 500;
      margin: 0;
    }
  
    img {
      width: 100%;
      aspect-ratio: 4/3;
      object-fit: cover;
      margin-top: 16px;
    }
  }

}

.article__title {
  padding: 16px 0px !important;
  margin-bottom: 0 !important;

  h1 {
    font-weight: 500;
    color: #02062C;
    margin: 0 !important;
  }
}

.date-posted span {
  color: #02062C !important;
}

.article__body {
  .image-container {
    margin-top: 48px;
  }

}

.text-preview {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* Hiển thị tối đa 3 dòng */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.post-label {
  display: block;
  width: fit-content;
  padding: 4px 12px;
  background: #2275D7;
  border-radius: 32px;
  margin-bottom: 8px !important;
}


.grid-container {
  display: grid;
  gap: 30px;

  @include mobile {
    grid-template-columns: repeat(1, 1fr);
  }

  @include tablet {
    grid-template-columns: repeat(2, 1fr);
  }

  @include desktop {
    grid-template-columns: repeat(3, 1fr);
  }
}

.stack-container {
  position: relative;
}

.image-container img {
  width: 100%;
  object-fit: cover;
}


.all-posts-container {
  padding-bottom: 0 !important;
}

.container_Tab {
  width: 100%;

  @media screen and (min-width: 1400px) {
    padding-left: 40px;
  }
}

.text-block {
  h2::after {
    content: '';
    margin-top: 8px;
    display: block;
    width: 100px;
    height: 2px;
    background-color: black;
    vertical-align: middle;
  }

  .block {
    h5 {}
  }

}



.grid-item {
  display: flex;
  flex-direction: column;

  .readmore {
    text-decoration: underline;
  }
}

.feature {
  width: 100%;
}

.feature__body {
  padding: 16px;

  h5 {
    margin-bottom: 12px !important;
  }

  .text-preview {
    margin-bottom: 12px !important;
  }
}

.block {
  text-decoration: none;
}


.profile section {
  // padding: 0 16px;
  // max-width: 1536px;
  width: 100%;
  position: relative;

  // margin-bottom: 80px;
  @include tablet() {
    // padding: 0 24px;
  }

  @include desktop() {
    // padding: 0 48px;
    // margin-bottom: 150px;
  }

  * {
    white-space: pre-line;
    word-break: break-word;
  }
}

.profile.freemium {
  background-color: #000;
}

.profile.justine {
  background-color: #f4f7fa;

  .sections-container {
    & .cta-container:nth-child(3) {
      .cta-content {
        background-color: #dfc593;
      }
    }

    & .cta-container:nth-child(7) {
      .cta-content {
        background-color: #a7c4ca;
      }
    }
  }
}


.profile.yosha {
  .sections-container {
    & .custom-three-container:nth-child(8) {
      .custom-three-content {
        .custom-items {
          .custom-item {
            background-color: #e5c7c9;

            .item-info {
              >h2 {
                color: #cd5d9d;
              }

              >p {
                color: #515151;
              }
            }
          }
        }
      }
    }
  }
}

.profile.intenxfitness {
  .sections-container {

    >*:nth-child(6),
    >*:nth-child(7) {
      background-color: #141414;
      color: white;
    }

    >*:nth-child(7) {
      .custom-one-content {
        .info {
          .info-descriptions {
            .description {
              font-size: 18px;
              font-weight: 400;
            }

            .description:nth-child(1),
            .description:nth-child(4) {
              font-size: 24px;
              font-weight: 700;
            }
          }
        }
      }
    }

    >*:nth-child(8) {
      .text-left-content {
        .info {
          .info-descriptions {
            .description {
              font-size: 18px;
              font-weight: 400;
            }

            .description:nth-child(1),
            .description:nth-child(4) {
              font-size: 24px;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}

.profile.default.somebodysgun {
  background-color: #f1f1f1;

  .sections-container {
    >.custom-three-container {
      background-color: transparent;
    }

    >div:nth-child(1 of .custom-three-container) {
      background-color: #1b90dd;

      .custom-three-content {
        .text {

          .header,
          .description {
            color: #fff;
          }
        }
      }
    }
  }
}

.profile {
  .title {
    color: #2a2a2a;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -1px;
    margin-bottom: 16px;
  }

  .sections-container {
    justify-content: flex-start;
    width: 100%;
    min-height: 100vh;
    justify-content: flex-start;

    >*:first-child {
      margin-top: 0 !important;
    }

    >div:nth-child(2n of .gallery-container) {
      .gallery-content {
        .gallery-text {
          align-self: flex-end;
        }
      }
    }

    >div:nth-child(2n + 1 of .gallery-container) {
      .gallery-content {
        .gallery-text {
          align-self: flex-start;
        }
      }
    }
  }

  @include tablet {
    .title {
      font-size: 24px;
      letter-spacing: -1px;
      margin-bottom: 36px;
    }
  }

  @include desktop {
    .title {
      font-size: 40px;
      letter-spacing: -1.8px;
      margin-bottom: 56px;
    }
  }
}

iframe,
iframe:focus-visible {
  outline: none;
}