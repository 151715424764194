@import "../../../../../../styles/variables.scss";

//Standard theme
.profile .gallery-container.standard {
  margin: 0;
  max-width: none;
  padding-left: 0;
  padding-right: 0;
  .gallery-content {
    .gallery-swiper {
      width: 100%;
      height: 100%;
      overflow: hidden;
      .swiper-slide {
        .gallery-item {
          aspect-ratio: 16/9;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            min-height: 100%;
            min-width: 100%;
          }
        }
      }
    }
  }
  @include tablet {
    .sub-title {
      font-size: 14px;
    }
  }
  @include desktop {
    .title {
      margin-bottom: 36px;
    }
    .sub-title {
      font-size: 18px;
      letter-spacing: -0.5;
    }
  }
  @include large-desktop {
    .title {
      margin-bottom: 36px;
    }
    .sub-title {
      font-size: 18px;
      letter-spacing: -0.5;
    }
  }
}
