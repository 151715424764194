@import "../../../../../styles/variables.scss";

//James
.profile .cta-container.freemium {
  overflow: hidden;
  padding: 0px 0px;
  .cta-content {
    max-width: none;
    padding: 0;
  }
  &.freemium.without-image {
    width: 100%;
    .cta-content {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      background-color: #b69e42;
      padding-top: 16px;
      padding-bottom: 16px;
      max-width: none;
      .info-descriptions {
        display: flex;
        flex-wrap: nowrap;
        gap: 16px;
        animation: 15s linear 1s infinite alternate ctaYosha;
      }
      .description {
        color: #fff;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
        white-space: nowrap;
        word-wrap: unset;
      }
    }
    @include tablet {
      .cta-content {
        padding-top: 24px;
        padding-bottom: 24px;
        .description {
          font-size: 18px;
        }
      }
    }
  }
}
.profile .cta-container.freemium.freemium.without-image.full-text {
  @include desktop {
    padding: 48px;
  }
}
.profile .cta-container.freemium.freemium.without-image.full-text .cta-content {
  background-color: #b69e42;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 36px 16px;
  margin: 24px 0;
  .info {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 1090px;
    .title {
      margin: 0;
      color: #fff;
      text-align: center;
      font-size: 36px;
      font-weight: 700;
      line-height: 150%; /* 60px */
      letter-spacing: -0.6px;
    }
    .info-descriptions {
      color: #fff;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: -0.3px; /* 30px */
      animation: none;
    }
  }
  .link {
    margin-top: 36px;
    button {
      padding: 20px 40px;
      background-color: #000;
      border-radius: 100px;
      p {
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -0.4px;
      }
    }
  }
  @include tablet {
    padding: 56px 24px;
    margin: 56px 0;
    .info {
      .title {
        margin: 0;
        color: #fff;
        text-align: center;
        font-size: 40px;
        font-weight: 700;
        line-height: 150%; /* 60px */
        letter-spacing: -0.6px;
      }
      .info-descriptions {
        color: #fff;
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: -0.5px; /* 30px */
        animation: none;
      }
    }
  }
  @include desktop {
    margin: 80px 0;
    padding: 100px;
    max-width: 1440px;
    border-radius: 12px;
  }
}

@keyframes ctaYosha {
  0% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(200px);
  }
}
