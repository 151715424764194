@import "../../../../../styles/variables.scss";

//Yosha theme
.profile .cta-container.yosha {
  overflow: hidden;
  padding: 0px 0px;
  .cta-content {
    max-width: none;
    padding: 0;
  }
  &.yosha.without-image {
    width: 100%;
    .cta-content {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      background-color: #fccccc;
      padding-top: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid #ee4659;
      border-top: 1px solid #ee4659;
      max-width: none;
      .info-descriptions {
        display: flex;
        flex-wrap: nowrap;
        gap: 16px;
        animation: 15s linear 1s infinite alternate ctaYosha;
      }
      .description {
        color: #ee4659;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -1px;
        text-transform: uppercase;
        white-space: nowrap;
        word-wrap: unset;
      }
    }
    @include tablet {
      .cta-content {
        border-bottom: 2px solid #ee4659;
        border-top: 2px solid #ee4659;
        padding-top: 24px;
        padding-bottom: 24px;
        .description {
          font-size: 18px;
        }
      }
    }
  }
}
@keyframes ctaYosha {
  0% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(200px);
  }
}
