.terms-section {
  .header {
    margin-bottom: 2rem;
    button {
      padding: 24px 24px 24px 0;
    }
    h1 {
      color: #0468be;
      text-align: left;
      font-size: 32px;
      font-family: "Inter", sans-serif;
      font-weight: 600;
    }
  }
  h3 {
    font-size: 24px;
    margin-bottom: 0.5rem;
    font-weight: 600;
    letter-spacing: -0.6px;
  }
  p.body-reg {
    margin-bottom: 3rem;
    line-height: 1.75;
    letter-spacing: -0.3px;
  }
  br {
    content: "A" !important;
    display: block !important;
    margin-bottom: 0.5rem !important;
  }
  ul.list {
    list-style: inside;
  }
  ol {
    list-style: decimal;
  }
  ol.list-roman {
    list-style-type: lower-roman;
    list-style-position: inside;
    li {
      &::marker {
        text-indent: 5px !important;
        margin-right: 5px !important;
      }
    }
  }
  strong {
    font-weight: 600;
  }
}
