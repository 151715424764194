@import "../../../../../../styles/variables.scss";

//Standard theme
.profile .bio-container.standard {
  width: 100%;
  max-width: none;
  background-color: #ccaec3;
  display: flex;
  align-items: center;
  justify-content: center;
  .bio-content {
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 64px;
    padding-bottom: 64px;
    margin: 0;
    align-items: start;
    gap: 24px;
    flex-wrap: nowrap;
    .title {
      color: white;
      margin: 0;
    }
    .content {
      width: 100%;
      row-gap: 24px;
      .header-item {
        align-self: flex-start;
        max-width: 900px;
        p {
          color: #fff;
          font-family: Inter;
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -1px;
        }
      }
      .des-items {
        max-width: 900px;
        align-self: flex-end;
        gap: 24px;
        .description {
          color: #fff;
          font-family: Inter;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 1.5;
          letter-spacing: -0.5px;
        }
      }
      .des-items.without-header {
        max-width: none;
      }
    }

    @include tablet {
      align-items: start;
      gap: 32px;
      flex-wrap: nowrap;
      .content {
        row-gap: 48px;
        .header-item {
          align-self: flex-start;
          max-width: 900px;
          p {
            color: #fff;
            font-family: Inter;
            font-size: 56px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -1.8px;
          }
        }
        .des-items {
          max-width: 900px;
          align-self: flex-end;
          gap: 24px;
          .description {
            color: #fff;
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 1.5;
            letter-spacing: -0.5px;
          }
        }
        .des-items.without-header {
          max-width: none;
        }
      }
    }
    @include desktop {
      padding-top: 120px;
      padding-bottom: 120px;
      gap: 48px;
    }
  }
}
