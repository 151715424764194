@import "../../../../../../styles/variables.scss";

.profile .gallery-container.default.bold-red {
  width: 100%;
  max-width: none;
  background-color: #88130f;
  .gallery-content {
    .title {
      color: #fff;
    }
    .gallery-text {
      .header {
        color: #fff;
      }
      .description {
        color: #fff;
      }
    }
  }
}
