/* Mixin */
/* Set các giá trị với màn hình tương ứng */
$mobile-width: 425px;
$tablet-width: 650px;
$desktop-width: 1024px;
$large-screen-width: 1440px;

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin large-desktop {
  @media (min-width: #{$large-screen-width}) {
    @content;
  }
}
