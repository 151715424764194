@import "../../../../../styles/variables.scss";

img {
  object-fit: cover;
}

.profile .awards-container.default {
  width: 100%;
  padding-top: 64px;
  padding-bottom: 64px;

  img {
    object-fit: cover;
  }
  .awards-content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    .title {
      width: 100%;
      text-align: left;
    }
    .awards-items {
      width: 100%;
      .awards-item {
        padding-top: 24px;
        padding-bottom: 24px;
        border-top: 1px solid #f5f5f5;
        flex-wrap: nowrap;
        justify-content: space-between;
        flex-wrap: nowrap;
        .info {
          align-items: start;
          flex-grow: 1;
          padding-right: 16px;
          width: 50%;
          .name {
            color: #151414;
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 1.5;
            letter-spacing: -0.5px;
          }
          .link {
            color: #616161;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 1.5;
            letter-spacing: -0.28px;
          }
        }
        .image {
          width: 56px;
          aspect-ratio: 1;
          overflow: hidden;
          border-radius: 4px;
          img {
            flex-shrink: 0;
            min-width: 100%;
            min-height: 100%;
          }
        }
      }
      .awards-item:first-child {
        border: none;
        padding-top: 0;
      }
    }
  }

  @include tablet {
    padding-top: 80px;
    padding-bottom: 80px;
    .awards-content {
      justify-content: space-between;
      flex-wrap: nowrap;
      gap: 48px;
      .title {
        width: 40%;
        max-width: 350px;
        margin-bottom: 0;
      }
      .awards-items {
        flex-grow: 1;
        .awards-item {
          padding-top: 32px;
          padding-bottom: 32px;
          .info {
            .name {
              font-size: 18px;
              letter-spacing: -0.72px;
            }
            .link {
              font-size: 14px;
              letter-spacing: -0.54px;
            }
          }
          .image {
            width: 100px;
            margin-left: 125px;
            aspect-ratio: 1;
          }
        }
      }
    }
  }
  @include desktop {
    padding-top: 120px;
    padding-bottom: 120px;
    .awards-content {
      justify-content: space-between;
      gap: 100px;
      .awards-items {
        flex-grow: 1;
        .awards-item {
          padding-top: 32px;
          padding-bottom: 32px;
          .info {
            .name {
              font-size: 24px;
              letter-spacing: -0.72px;
            }
            .link {
              font-size: 18px;
              letter-spacing: -0.54px;
            }
          }
          .image {
            width: 100px;
            margin-left: 125px;
            aspect-ratio: 1;
          }
        }
      }
    }
  }
}
