@import "../../../../../styles/variables.scss";

//Default theme
.profile .process-container.default.rimal {
  background-color: #f5f9fd;
  .process-content {
    .process-items {
      .process-item.show {
        .question-item {
          .question {
            color: #0644fe;
          }
          > svg {
            color: #0644fe;
          }
        }
        .answer {
          display: block;
        }
      }
    }
  }
}
