@import "../../../../../../styles/variables.scss";

.profile .text-left-container.default.rimal {
  background-color: #011548;
  .text-left-content {
    .info {
      .title,
      .description {
        color: #fff;
      }
    }
  }
}
