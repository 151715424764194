@import "../../../../../../styles/variables.scss";

//Yosha theme
.profile .custom-one-container.yosha {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding-top: 64px;
  padding-bottom: 64px;
  .custom-one-content {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 24px;
    .image {
      width: 100%;
      aspect-ratio: 16/9;
      overflow: hidden;
      border-radius: 12px;
    }
    .info {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 100%;
      gap: 8px;
      .title {
        color: #151414;
        font-family: Inter;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 1.2;
        letter-spacing: -1.5px;
        margin-bottom: 0;
      }
      .info-descriptions {
        display: flex;
        flex-direction: column;
        gap: 24px;
        .description {
          color: #616161;
          font-family: Inter;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 1.5;
          letter-spacing: -0.3px;
        }
      }
    }
  }
  @include tablet {
    .custom-one-content {
      gap: 32px;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      .image {
        width: 100%;
        aspect-ratio: 16/9;
        overflow: hidden;
      }
      .info {
        width: 100%;
        gap: 16px;
        .title {
          font-size: 36px;
        }
        .info-descriptions {
          .description {
            font-size: 21px;
          }
        }
      }
    }
  }
  @include desktop {
    padding-top: 150px;
    padding-bottom: 150px;
    .custom-one-content {
      gap: 100px;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;
      .image {
        width: 100%;
        aspect-ratio: 0.89;
        max-width: 600px;
      }
    }
  }
  @include large-desktop {
    .custom-one-content {
      .info {
        .title {
          font-size: 48px;
        }
      }
    }
  }

  &.yosha.without-image {
    .custom-one-content {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .info {
        width: 100%;
        max-width: 1250px;
        .title {
          width: 100%;
          text-align: left;
          max-width: 1250px;
          color: #6349a0;
          font-family: Inter;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 1.5; /* 64.8px */
          letter-spacing: -0.5px;
          text-transform: uppercase;
        }
        .description {
          width: 100%;
          text-align: left;
          max-width: 686px;
          color: #b6b6b6;
          font-family: Inter;
          font-size: 16px;
          font-style: italic;
          font-weight: 600;
          line-height: 150%; /* 30px */
          letter-spacing: -0.8px;
          max-width: 686px;
        }
        @include tablet {
          width: 70%;
          .title {
            font-size: 28px;
            letter-spacing: -0.8px;
          }
          .description {
            width: 70%;
            font-size: 18px;
            letter-spacing: -0.6px;
          }
        }
        @include desktop {
          gap: 48px;
          .title {
            font-size: 36px;
            letter-spacing: -1.2px;
          }
          .description {
            font-size: 20px;
            letter-spacing: -0.6px;
          }
        }
      }
    }
  }
}
