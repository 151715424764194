.download-page {
    justify-content: center !important;
    flex-direction: column !important;
    align-items: center !important;
    gap: 32px !important;
    background: #fafafa !important;
    padding-top: 80px !important;
}

.download-title {
    font-weight: 600;
    font-size: 24px !important;
    margin: 0 !important;
}
.signout-btn {
    width: fit-content !important;
    top: 3rem !important;
    position: absolute !important;
    left: 1rem !important;
    color: #02062c !important;
    font-size: 18px !important;
    
}
.signout-btn::before{
    content: url('../../../assets/log-out.svg') !important;
    display: block !important;
    margin-top:2px !important;
}
.subtitle {
    margin: 0!important;
    color: #02062c !important;
}



@media screen and (min-width: 992px){
   .download-page {
        gap: 64px !important;
   }
   .download-title {
        font-size: 44px !important;
   }
   .subtitle {
    font-size: 16px !important;
   }
}

@media screen and (min-width: 1200px) {
    .signout-btn {
        top: 4rem !important;
        left: 4rem !important;
        width: fit-content !important;
    }
}
