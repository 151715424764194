@import "../../../../../../styles/variables.scss";

.profile .bio-container.default.rimal {
  background-color: #011548;
  .bio-content {
    .title,
    .description {
      color: #fff;
    }
  }
}
