@import "../../styles/variables.scss";

.help-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 8px;
    cursor: pointer;
    max-width: 150px;
    background-color: #fff;
    transition: background-color 0.3s ease, box-shadow 0.3s ease; // Transition for smooth animation

    img {
        width: 24px;
        height: 24px;
        margin-right: 8px;
    }

    h5 {
        font-weight: bold;
    }

    &:hover {
        background-color: #f0f8ff; // Change background color on hover (you can customize)
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); // Add shadow effect on hover
    }
}

.tab {
    // padding: '24px 24px 24px 24px';
    border: 1px solid #ccc;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
}

.column {
    display: flex;
    flex-direction: column;
}

.more-button {
    background: none;
    border: 1px solid transparent;
    border-radius: 4px;
    color: black;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 12px;
    cursor: pointer;
    position: relative;
    transition: border 0.2s ease;

    &:hover {
        border: 1px solid #007bff;
        border-radius: 8px;
    }
}

.delete-button {
    cursor: pointer;
    padding-left: 16px;
    padding-right: 16px;
}

.tab-card {
    width: 380px;
    margin: 2rem;
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border: 2px solid #0b86e7;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        // transform: scale(1.05); // Phóng to nhẹ khi hover
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); // Shadow lớn hơn khi hover
    }

    @include mobile {
        width: auto;

    }


}


.tab-card-inside {
    width: 380px;
    padding: 24px;


    @include mobile {
        width: auto;
    }

    // @include tablet {
    //     width: auto;
    // }
}

.icon-delete {
    filter: grayscale(100%) brightness(50%);
    transition: filter 0.3s ease;

    &:hover {
        filter: grayscale(0%) brightness(0%);
    }
}


.row {
    display: flex;
    flex-direction: row;
}

.hover-container {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
    padding: 8px;
    cursor: pointer;
    margin-bottom: 60px;

    h5 {
        font-weight: bold;
        margin: 0;
    }

    &:hover {
        h5 {
            text-decoration: underline;
        }
    }

    .border-animation {
        border: 2px solid transparent;
        transition: border-color 0.3s ease-in-out;
    }

    .border-animation.active {
        border-color: blue;
    }

    /* Card styles */
    .card {
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        overflow: hidden;
    }

    .card-header {
        padding: 16px;
        border-bottom: 1px solid #eee;
    }

    .card-content {
        padding: 16px;
    }

    .card-footer {
        padding: 16px;
        border-top: 1px solid #eee;
    }

    /* Button styles */
    .button {
        display: inline-block;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        text-decoration: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s, color 0.3s;
    }

    .button-primary {
        background-color: #0066cc;
        color: #fff;
        border: none;
    }

    .button-primary:hover {
        background-color: #0052a3;
    }

    .button-outline {
        background-color: transparent;
        color: #0066cc;
        border: 1px solid #0066cc;
    }

    .button-outline:hover {
        background-color: #e6f3ff;
    }

    /* Badge styles */
    .badge {
        display: inline-block;
        padding: 4px 8px;
        font-size: 12px;
        font-weight: bold;
        border-radius: 16px;
        text-transform: uppercase;
    }

    .badge-blue {
        background-color: #e6f3ff;
        color: #0066cc;
    }

    .badge-green {
        background-color: #e6fff0;
        color: #00cc66;
    }
}

.main-content-billing-main {
    padding-left: 120px;
    padding-right: 120px;
    padding-top: 20px;
    padding-bottom: 50px;

    @include mobile {
        padding-left: 24px;
        padding-right: 24px;
    }

    @include tablet {
        padding-left: 64px;
        padding-right: 64px;
    }
}

.plan-page-main-content {
    padding: 0px 120px;

    @include mobile {
        padding-left: 24px;
        padding-right: 24px;
    }

    @include tablet {
        padding-left: 64px;
        padding-right: 64px;
    }
}