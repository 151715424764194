@import "../../styles/variables.scss";

.profile .footer.default {
  padding: 32px 16px;
  gap: 2rem;
  width: 100%;
  align-items: center;
  margin: 0;
  justify-content: center;
  flex-direction: column;
  .btn-to-top {
    display: none;
  }
  .navigation {
    width: 100%;
    max-width: 1440px;
    gap: 36px;
    align-items: flex-start;
    .text {
      width: 100%;
      .pro_name {
        font-size: 22px;
        font-style: normal;
        line-height: normal;
        margin-bottom: 8px;
        text-transform: uppercase;
        @include tablet {
          font-size: 64px;
        }
      }
      .copy-right {
        font-size: 12px;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.3px;
      }
    }
    .pages {
      width: 100%;
      align-items: flex-start;
      gap: 16px;
      > p {
        color: #616161;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.5px;
      }
      ul {
        align-items: flex-start;
        gap: 16px;
        li {
          button {
            color: #000;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.5px;
            width: max-content;
          }
        }
      }
    }
    .policies {
      width: 100%;
      align-items: start;
      gap: 16px;
      > p {
        color: #616161;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.5px;
      }
      > ul {
        align-items: start;
        gap: 16px;
        li a {
          display: block;
          color: #000;
          width: max-content;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.5px;
          width: max-content;
        }
      }
    }
  }
  @include tablet {
    padding: 56px 24px;
    padding-top: 32px;
    gap: 48px;
    .navigation {
      width: 100%;
      .text {
        width: 100%;
        .pro_name {
          font-size: 48px;
        }
      }
      .pages {
        width: max-content;
        flex-grow: 1;
        gap: 16px;

        ul {
          align-items: flex-start;
          flex-direction: row;
          gap: 16px;
          li {
            button {
              font-size: 18px;
              letter-spacing: -0.8px;
            }
          }
        }
      }
      .policies {
        ul {
          flex-direction: row;
          li a {
            font-size: 18px;
            letter-spacing: -0.8px;
          }
        }
      }
    }
  }
  @include desktop {
    padding: 60px 30px;
    .navigation {
      flex-wrap: nowrap;
      justify-content: space-between;
      gap: 200px;
      .text {
        width: 100%;
        .pro_name {
          font-size: 40px;
        }
      }
      .pages {
        flex-grow: 1;
        width: max-content;
        ul {
          flex-direction: column;
        }
      }
      .policies {
        flex-grow: 1;
        width: max-content;
        ul {
          flex-direction: column;
        }
      }
    }
  }
}

.profile .footer.freemium {
  .navigation {
    .text {
      .pro_name {
        color: #fff;
      }
      .copy-right {
        color: #fff;
      }
    }
    .pages,
    .policies {
      > p {
        color: #c4c4c4;
      }
      ul {
        li {
          button,
          a {
            color: #fff;
          }
        }
      }
    }
  }
}

.profile .footer.default.intenxfitness {
  background-color: #141414;
  color: white;
  .pro_name {
    color:white;
  }
  .pages,
  .policies {
    > p {
      color: #c5c5c5;
    }
    ul {
      li {
        button,
        a {
          color: #fff;
        }
      }
    }
  }
}

.profile .footer.bold-red {
  background-color: #88130f;
  .navigation {
    .pages,
    .policies {
      > p {
        color: #c4c4c4;
      }
      ul {
        li {
          button,
          a {
            color: #fff;
          }
        }
      }
    }
  }
}
