@import "../../styles/variables.scss";


.container-main {
    background: linear-gradient(180deg, #E0EFFF 0%, #FAFAFA 100%);
    width: 100vw;
    height: 100vh;

}

.main-content-login-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    padding: 80px 0;
}

/* Logo CastleBird */
.logo-container {
    width: 144px;
    height: 31px;
}

.center-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    margin: 24px 0;
}

/* main title */
.main-title {
    font-weight: bold;
    font-size: 32px;
    line-height: 38.72px;
    color: black;
    text-align: center;
    margin-bottom: 16px;
}

.main-title span {
    color: #0B86E7;
}

.description {
    font-size: 15px;
    color: #616161;
    line-height: 21px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 40px;
}

.google-login-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 16px 12px;
    border-radius: 8px;
    background-color: #0B86E7;
    box-shadow: 0px 4px 5px 0px #0000001A;
}

.google-login-button-icon {
    margin-left: 110.5px;
    margin-right: 4px;
    height: 16px;
    width: 16px;

    @include mobile {
        margin-left: 70px;
    }
}


.google-login-button-text {
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    line-height: 18.15px;
    margin-right: 110.5px;

    @include mobile {
        margin-right: 70px;
    }
}


/* Spacing */
.divider {
    display: flex;
    align-items: center;
    width: 70%;
    margin: 32px 0;
}

.divider-line {
    flex: 1;
    height: 1px;
    background-color: #c4c4c4;
}

.divider-text {
    margin: 0 40px;
    color: #c4c4c4;
    font-size: 16px;
    font-weight: 600;
}

/* Button "Continue as Guest" */
.guest-login-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 12px;
    border: 1px solid #D8D8D8;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0px 4px 5px 0px #0000001A;
    margin: 0;
}

.guest-button-text {
    font-size: 15px;
    font-weight: 600;
    line-height: 18.15px;
    color: black;
    margin: 0 136.5px;

    @include mobile {
        margin: 93px;
    }
}

.policy-text {
    font-size: 14px;
    line-height: 19.6px;
    text-align: center;
    font-weight: 500;
}

.policy-text a {
    text-decoration: underline;
    color: #0B86E7;
}