@import "../../../../../../styles/variables.scss";

//Standard theme
.profile .social-container.standard {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding-top: 64px;
  padding-bottom: 64px;
  .title {
    color: #000;
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -1.8px;
    margin-bottom: 32px;
  }
  .social-items {
    gap: 16px;
    .social-item {
      height: 38px;
      aspect-ratio: 1;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
    }
  }
  @include tablet {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    gap: 48px;
    .title {
      width: 100%;
      margin-bottom: 0;
    }
    .social-items {
      width: 100%;
      flex-grow: 1;
      gap: 40px;
      padding: 0;
      .social-item {
        height: 48px;
        svg {
          transform: scale(1.3);
        }
      }
    }
  }
  @include desktop {
    gap: 100px;
    .social-items {
      .social-item {
        height: 64px;
        svg {
          transform: scale(1.5);
        }
      }
    }
  }
}
