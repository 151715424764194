@import "../../../../styles/variables.scss";

/* Navbar Container */
.navbar-login {
    width: 100vw;
    height: 64px;
    padding: 12px 64px;
    background-color: #F9FAFC;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @include mobile {
        padding: 12px 32px;
    }

}

/* Logo Container */
.navbar-login-logo {
    height: 34px;
    object-fit: contain;

    @include mobile {
        height: 25px;
    }
}

/* User Info Container */
.navbar-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.25rem 0.5rem;
    border-radius: 0.375rem;
    cursor: pointer;

    &:hover {
        background-color: #E5E7EB;
        /* Tailwind gray-200 */
    }
}

.navbar-user-avatar {
    height: 32px;
    width: 32px;
    border-radius: 50%;
}

.navbar-user-name {
    margin-left: 8px;
    font-weight: 500;
    font-size: 14px;
}

/* Dropdown Menu */
.dropdown-menu {
    position: absolute;
    right: 0;
    margin-top: 220px;
    margin-right: 54px;
    background-color: #ffffff;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 4px 0;
}

.dropdown-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;

    .dropdown-header-avatar {
        height: 32px;
        width: 32px;
        border-radius: 50%;
    }

    .dropdown-header-info {
        margin-right: 1rem;

        h4 {
            font-size: 1rem;
            font-weight: bold;
        }

        p {
            font-size: 0.875rem;
            color: #616161;
            /* Tailwind gray-500 */
        }
    }
}

.dropdown-item {
    padding: 10px 20px;
    font-size: 1rem;

    &:hover {
        background-color: #F3F4F6;
        /* Tailwind gray-100 */
        border-radius: 0.5rem;
    }
}

/* Sign In Button */
.navbar-signin {
    cursor: pointer;
    background-color: #0B86E7;
    /* Tailwind blue */
    padding: 10px 20px;
    color: white;
    border-radius: 0.5rem;
    font-size: 16px;
    font-weight: 700;
    user-select: none;
}

.navbar-billing {
    box-shadow: 0px 4px 15px 0px #0000001A;
    padding: 16px 64px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    display: flex;
    height: 71px;

    @include mobile {
        padding: 16px 24px;
    }
}