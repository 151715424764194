@import "../../../../../../styles/variables.scss";

.profile .update-container.standard {
  width: 100%;
  background-color: #fde2e2;
  > .update-content {
    padding-top: 64px;
    padding-bottom: 64px;
    > p {
      color: #151414;
      font-family: Inter;
      font-size: 21px;
      font-style: italic;
      font-weight: 200;
      line-height: normal;
      letter-spacing: -1px;
      text-align: center;
    }
    .title {
      color: #a12a5d;
      text-align: center;
      font-family: Inter;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 1.2;
      letter-spacing: -1.8px;
      margin-bottom: 32px;
    }
    .update-items {
      align-items: flex-start;
      row-gap: 56px;
      .update-card {
        width: 100%;
        height: 100%;
        gap: 24px;
        .update-image {
          aspect-ratio: 1;
          overflow: hidden;
          border-radius: 24px;
        }
        .update-info {
          flex-grow: 1;
          gap: 12px;
          align-items: start;
          justify-content: space-between;
          .update-text {
            align-items: flex-start;
            gap: 8px;
            .update-title {
              color: #000;
              font-family: Inter;
              font-size: 21px;
              font-style: normal;
              font-weight: 600;
              line-height: 30px; /* 142.857% */
              letter-spacing: -0.8px;
              text-transform: uppercase;
            }
            .update-description {
              color: #151414;
              font-family: Inter;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 1.5;
              letter-spacing: -0.28px;
            }
            .update-description.des-overflow {
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
            .update-description.des-overflow.des-all {
              display: -webkit-box;
              -webkit-line-clamp: unset;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }
          > button {
            padding: 0;
            margin-top: 28px;
            p {
              color: #a12a5d;
              font-family: Inter;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              letter-spacing: -0.5px;
              text-decoration: underline;
            }
          }
        }
      }
    }
    > button {
      margin-top: 90px;
      padding: 16px 32px;
      background-color: #a12a5d;
      border-radius: 26px;
      p {
        color: #fff;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.5px;
      }
    }
    .update-popup-container {
      position: fixed;
      top: 0;
      left: 0;
      width: 100dvw;
      height: 100dvh;
      background-color: #151414;
    }
    @include tablet {
      > p {
        color: #151414;
        font-family: Inter;
        font-size: 24px;
        font-style: italic;
        font-weight: 200;
        line-height: normal;
        letter-spacing: -1px;
        text-align: center;
      }
      .title {
        color: #a12a5d;
        text-align: center;
        font-family: Inter;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 1.5;
        letter-spacing: -1.8px;
      }
      .update-items {
        align-items: flex-start;
        .update-card {
          width: 100%;
          height: 100%;
          gap: 24px;
          .update-image {
            aspect-ratio: 1;
            overflow: hidden;
            border-radius: 24px;
          }
          .update-info {
            flex-grow: 1;
            gap: 12px;
            align-items: start;
            justify-content: space-between;
            .update-text {
              align-items: flex-start;
              gap: 8px;
              .update-title {
                color: #000;
                font-family: Inter;
                font-size: 21px;
                font-style: normal;
                font-weight: 600;
                line-height: 30px; /* 142.857% */
                letter-spacing: -0.8px;
                text-transform: uppercase;
              }
              .update-description {
                color: #151414;
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 1.5;
                letter-spacing: -0.28px;
              }
              .update-description.des-overflow {
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
              }
              .update-description.des-overflow.des-all {
                display: -webkit-box;
                -webkit-line-clamp: unset;
                -webkit-box-orient: vertical;
                overflow: hidden;
              }
            }
            > button {
              padding: 0;
              margin-top: 28px;
              p {
                color: #a12a5d;
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                letter-spacing: -0.5px;
                text-decoration: underline;
              }
            }
          }
        }
      }
      > button {
        margin-top: 90px;
        padding: 16px 32px;
        background-color: #a12a5d;
        border-radius: 26px;
        p {
          color: #fff;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.5px;
        }
      }
      .update-items {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 24px;
      }
    }
    @include desktop {
      padding-top: 80px;
      padding-bottom: 80px;
      .title {
        margin-bottom: 64px;
      }
      .update-items {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 36px;
        .update-card {
          margin-bottom: 0;
        }
      }
    }
    @include large-desktop {
      .title {
        margin-bottom: 80px;
      }
      .update-items {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 90px;
        .update-card {
          margin-bottom: 0;
        }
      }
    }
  }
}
