@import "../../../styles/variables.scss";

.nav-mobile-container {
  position: fixed;
  transition: var(--transition-all);
  display: none;
  animation: fadeIn 300ms ease-in-out;
  @include mobile() {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100vh;
    width: 100%;
    padding: 16px;
    right: 0;
    top: 0;
    background-color: var(--white);
    z-index: 30;
    box-shadow: -8px 60px 20px rgba(0, 0, 0, 0.1);
    gap: 2rem;
    .nav-mobile-menu {
      width: 24px;
    }
    .top-bar {
      width: 100%;
      justify-content: space-between;
      h3 {
        font-size: 18px;
        font-weight: 600;
        letter-spacing: -0.5px;
      }
    }
    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: center;
      gap: 1rem;
      height: 80%;
      width: 100%;
      li {
        width: 100%;
      }
      .logout-btn {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: end;
        button {
          padding: 0;
          color: red;
          background-color: transparent;
          .text {
            font-weight: 600;
          }
        }
        flex-grow: 1;
      }
    }
  }
}

.page-list {
  > li {
    cursor: pointer;
    span {
      font-size: 21px;
      font-family: "Inter", sans-serif;
      font-weight: 500;
      letter-spacing: -0.7px;
    }
    span.active-page {
      color: #0468be;
      font-weight: 700;
      text-decoration: underline;
    }
  }
}

.nav-mobile-container {
  @include mobile {
    &.visible {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.2s ease-in;
    }
    &.hidden {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s 0.2s, opacity 0.3s ease-out;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
