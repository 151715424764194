@import "../../styles/variables.scss";

.profile .nav-profile-container {
  width: 100%;
  padding: 16px;
  margin: 0;
  z-index: 99;
  max-width: none;

  .nav-desktop {
    display: none;
  }

  .nav-mobile {
    width: 100%;
    justify-content: space-between;

  }

  .right-nav-mobile {
    svg {
      color: #000;
    }

    margin-top: 0;
  }

  @include tablet {
    padding: 24px;

    .nav-mobile {
      display: none;
    }

    .nav-desktop {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      gap: 80px;

      .header {
        width: 30%;
        align-items: flex-start;

        .pro-name {
          color: #000;
          font-family: Inter;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.5px;
          text-transform: uppercase;
        }

        .cb-made {
          color: #616161;
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.3px;

          span {
            color: #151414;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.3px;
            text-decoration-line: underline;
          }
        }
      }

      .list-items {
        flex-grow: 1;
        width: auto;
        column-gap: 32px;
        row-gap: 16px;
        justify-content: flex-end;

        li {
          cursor: pointer;

          span {
            color: #616161;
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.5px;
          }
        }

        .active-page span {
          color: #000;
          cursor: default;
          font-weight: 500;
        }
      }
    }
  }

  @include desktop {
    padding: 48px;
  }
}

.profile.intenxfitness .nav-profile-container {
  .nav-desktop {
    .list-items {
      li span {
        color: #616161;
      }

      .active-page span {
        color: #141414;
      }
    }
  }
}

.pro-logo {
  height: 56px;
  width: 112px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  >img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
  }

  @include tablet {
    height: 72px;
    width: 144px;
  }

  @include desktop {
    height: 80px;
    width: 160px;
  }

  @include large-desktop {
    height: 120px;
    width: 240px;
  }
}

.profile .nav-profile-container.with-logo {
  padding: 16px;

  @include tablet {
    padding: 16px 24px;
  }

  @include desktop {
    padding: 16px 48px;
  }
}

.profile.freemium .nav-profile-container.with-logo {
  .nav-desktop {
    .header {
      .pro-logo {
        @include tablet {
          height: 64px;
          width: 64px;
        }

        @include desktop {
          height: 80px;
          width: 80px;
        }
      }
    }
  }

  nav {
    ul {
      li span {
        color: #fff;
      }

      li.active-page span {
        color: #b69e42 !important;
      }
    }
  }

  .right-nav-mobile {
    svg {
      color: #fff;
    }
  }

  @include tablet {
    padding: 24px;
  }

  @include desktop {
    padding: 24px 64px;
  }
}

.profile.default.somebodysgun .nav-profile-container {
  background-color: #18181c;

  .nav-desktop {
    .header {
      * {
        color: #fff;
      }
    }

    .list-items {
      * {
        color: #c5c5c5;
      }

      .active-page span {
        color: #fff;
      }
    }
  }

  .nav-mobile {
    .header {
      * {
        color: #fff;
      }
    }

    svg {
      color: #fff;
    }
  }
}