@import "../../../../../../styles/variables.scss";

.profile .update-container.default {
  padding-top: 32px;
  padding-bottom: 32px;
  .update-items {
    display: grid;
    grid-template-columns: 1fr;
    align-items: start;
    column-gap: 35px;
    row-gap: 56px;
    .update-card {
      .avatar {
        height: 26px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        aspect-ratio: 1;
        border-radius: 100px;
        background-color: #d9d9d9;
        img {
          min-width: 100%;
          min-height: 100%;
          flex-shrink: 0;
          object-fit: cover;
        }
      }
      .name {
        color: #000;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.3px;
      }
      .divider {
        height: 5px;
        width: 5px;
        border-radius: 50%;
        background-color: #d9d9d9;
      }
      .time {
        color: #616161;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.3px;
      }
      .update-image {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;
        aspect-ratio: 1;
        overflow: hidden;
        border-radius: 6px;
        background-color: #e5e5e5;
        > img {
          min-width: 100%;
          min-height: 100%;
          flex-shrink: 0;
          object-fit: cover;
        }
      }
      .update-title {
        color: #151414;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 1.5;
        letter-spacing: -0.36px;
        margin-bottom: 8px;
      }
      .update-description {
        color: #1d1d1d;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: -0.5px;
      }
      .update-description.des-overflow {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .update-description.des-overflow.des-all {
        display: -webkit-box;
        -webkit-line-clamp: unset;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      > button {
        margin: 24px 0;
        padding: 0;
        color: #616161;
      }
    }
  }
  .update-popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100dvw;
    height: 100dvh;
    background-color: #151414;
  }
  @include tablet {
    padding-top: 64px;
    padding-bottom: 64px;
    .update-items {
      grid-template-columns: 1fr 1fr;
      .update-card {
        .avatar {
          background-color: #616161;
          height: 48px;
        }
        .name {
          font-size: 18px;
        }
        .time {
          font-size: 16px;
        }
        .update-title {
          font-size: 24px;
        }
        .update-description {
          font-size: 18px;
        }
      }
    }
  }
  @include desktop {
    margin-top: 150px;
    margin-bottom: 150px;
    .title {
      margin-bottom: 36px;
    }
    .update-items {
      grid-template-columns: 1fr 1fr 1fr;
      .update-card {
        margin-bottom: 0;
        .avatar {
          height: 50px;
        }
        .name {
          font-size: 18px;
        }
        .time {
          font-size: 16px;
        }
        .update-title {
          font-size: 24px;
        }
        .update-description {
          font-size: 18px;
        }
      }
    }
  }
  @include large-desktop {
    .update-items {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
}
