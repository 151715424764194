@import "../../../../styles/variables.scss";

//Standard theme
.profile .hero-left-container.standard {
  background-color: #fcf4ec;
  width: 100%;
  .hero-left-content {
    position: relative;
    padding: 0;
    margin: 0;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 24px;
    .image {
      width: 100%;
      height: 100%;
      // aspect-ratio: 1;
      overflow: hidden;
    }
    .info {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 100%;
      gap: 8px;
      padding: 16px;
      // padding-top: 64px;
      // padding-bottom: 64px;
      .title {
        color: #000;
        text-align: center;
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%; /* 48px */
        letter-spacing: -2px;
        margin-bottom: 5px;
      }
      .description {
        color: #606060;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
        letter-spacing: -0.28px;
        margin-bottom: 16px;
      }
    }
    .info.without-image {
      padding: 16px;
    }
  }
  @include tablet {
    padding: 0;
    .hero-left-content {
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;
      .image {
        width: 100%;
        max-width: 620px;
        aspect-ratio: 0.9;
        overflow: hidden;
      }
      .info {
        position: relative;
        left: auto;
        top: auto;
        transform: translate(0, 0);
        .title {
          font-size: 48px;
          margin-bottom: 10px;
        }
        .description {
          font-size: 16px;
          margin-bottom: 24px;
        }
      }
      .info.without-image {
        padding: 48px;
      }
    }
  }
  @include large-desktop {
    .hero-left-content {
      .info {
        .title {
          font-size: 80px;
          margin-bottom: 24px;
          letter-spacing: -4px;
        }
        .description {
          font-size: 21px;
          margin-bottom: 64px;
        }
      }
    }
  }
}

.profile .hero-left-container.without-image {
  .info {
    .title {
      text-align: center;
      line-height: 1.5;
    }
    .info-descriptions {
      width: 100%;
    }
    .description {
      text-align: center;
      line-height: 1.5;
    }
  }
  @include desktop {
    .info {
      width: 100%;
      gap: 100px;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      .title {
        width: 100%;
      }
      .info-descriptions {
        flex-grow: 1;
      }
    }
  }
}
.profile .hero-left-container.standard.without-text {
  background-color: transparent;
  .hero-left-content {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    .image {
      width: 100%;
      aspect-ratio: 21/9;
      max-width: none;
    }
  }
}

//Default theme
.profile .hero-left-container.default {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  .hero-left-content {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 24px;
    .image {
      width: 100%;
      aspect-ratio: 16/9;
      overflow: hidden;
    }
    .info {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 100%;
      gap: 8px;
      .title {
        color: #151414;
        font-family: Inter;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -1.5px;
        margin-bottom: 0;
      }
      .info-descriptions {
        display: flex;
        flex-direction: column;
        gap: 24px;
        .description {
          color: #616161;
          font-family: Inter;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 1.5;
          letter-spacing: -0.3px;
        }
      }
    }
  }
  @include tablet {
    .hero-left-content {
      gap: 32px;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      .image {
        width: 100%;
        aspect-ratio: 16/9;
        overflow: hidden;
      }
      .info {
        width: 100%;
        gap: 16px;
        .title {
        }
        .info-descriptions {
          .description {
            font-size: 21px;
          }
        }
      }
    }
  }
  @include desktop {
    .hero-left-content {
      gap: 100px;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;
      padding: 0;
      padding-left: 48px;
      .image {
        width: 100%;
        aspect-ratio: 0.89;
        max-width: 600px;
      }
      .info {
        .title {
          font-size: 64px;
          letter-spacing: -2px;
        }
      }
    }
  }
}

//Freemium theme
.profile .hero-left-container.freemium {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  .hero-left-content {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 24px;
    .image {
      width: 100%;
      aspect-ratio: 16/9;
      overflow: hidden;
    }
    .info {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 100%;
      gap: 8px;
      .title {
        color: #151414;
        font-family: Inter;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -1.5px;
        margin-bottom: 0;
      }
      .info-descriptions {
        display: flex;
        flex-direction: column;
        gap: 24px;
        .description {
          color: #616161;
          font-family: Inter;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 1.5;
          letter-spacing: -0.3px;
        }
      }
    }
  }
  @include tablet {
    padding-top: 80px;
    padding-bottom: 80px;
    .hero-left-content {
      gap: 32px;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      .image {
        width: 100%;
        aspect-ratio: 16/9;
        overflow: hidden;
      }
      .info {
        width: 100%;
        gap: 16px;
        .title {
        }
        .info-descriptions {
          .description {
            font-size: 21px;
          }
        }
      }
    }
  }
  @include desktop {
    padding-top: 150px;
    padding-bottom: 150px;
    .hero-left-content {
      gap: 100px;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;
      padding: 0;
      padding-left: 48px;
      .image {
        width: 100%;
        aspect-ratio: 0.89;
        max-width: 600px;
      }
      .info {
        .title {
          font-size: 64px;
          letter-spacing: -2px;
        }
      }
    }
  }
}

.profile .hero-left-container.without-text {
  .image {
    margin: 0;
    width: 100% !important;
    max-width: none !important;
    aspect-ratio: 21/9 !important;
  }
}
