@import "../../../styles/variables.scss";

button {
  color: #f7f7f7;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  letter-spacing: -0.32px;
}

section {
  // padding: 56px 16px;
  width: 100%;
  position: relative;
  max-width: none;
  @include tablet() {
    // padding: 56px 24px;
  }
  @include desktop() {
    // padding: 100px 48px;
  }
  @include large-desktop() {
    // padding: 100px 48px;
  }
}
