.image-placeholder {
    width: 100%;
    height: 240px; 
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loadingShimmer 1.5s infinite;
    border-radius: 4px;
  }

.p-button-primary:disabled {
  background-color: #adabd2 !important; 
  color: #1d1833 !important; 
  cursor: not-allowed !important; 
  opacity: 0.7 !important;
  border:none !important;
}

.p-card-content .article__body .post-title {
  font-size: 1.5rem !important;
}
  
.input-text-full::placeholder {
  font-family: var(--font-family) !important;
  font-weight: 400 !important;
}


  .blog-onboarding .p-button-primary, .post-ready-card .p-button-primary {
    width: 100%;
  }
  .p-button-text {
    word-wrap: break-word;
    line-height: 140%;
    margin:0 !important;
  }
  .post-content {
    font-size: 12px !important;
    user-select: none; 
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
  }

  .final-card-step {
    margin-top: 80px !important;
    height: 120vh;
  }
  .post-ready-card {
    .p-card-content {
      display:flex;
      flex-direction: column;
      align-items: center;
    }
  }

  @media screen and (min-width:768px) {
    .post-content {
      font-size: 14px !important;
    }

    .image-placeholder {
      height: 280px !important;
    }
  }

  @media screen and (min-width: 1200px) {
    .final-card-step {
      margin-top: 0px !important;
    }
  }

  @media screen and (min-width: 1400px) {
    .final-card-step {
      height: 90vh !important;
    }
    .post-content {
      font-size: 16px !important;
    }
  }

  @keyframes loadingShimmer {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
