.p-checkbox-box {
    border: 1px solid;
}

.reference-container {
    margin-top: 0 !important;
}

.p-button-secondary , .warning {
    background-color: hsl(347, 100%, 80%) !important;
    color: #02062c !important;
}
.p-button-primary:hover {
    background-color: rgb(18, 35, 104) !important;
}

.p-button-secondary:hover , .warning:hover {
    background-color: hsl(347, 100%, 70%) !important;
}

.p-dialog {
    padding: 24px 28px;
    background: #fafafa;
    display: flex;
    align-items: center;
    box-shadow: none;
}
.p-dialog-footer {
    padding-top: 16px;
    width: 100%;
    .button {
        color: #02062c;
        padding:24px 28px;
        border-radius: 4px !important;
    }
}
.cancel-btn {
    padding-left: 0px !important;
}
.unsubscribe-btn {
    background: #02062c;
    color: #fafafa !important;
}
// .infor::before {
//     content: url('../../assets/mdi-light_information.svg');
//     position: relative;
//     top: 4px;
//     padding-right: 4px;
// }