@import "../../../../../../styles/variables.scss";

.profile .testimonial-container.freemium {
  padding-top: 64px;
  padding-bottom: 64px;
  overflow: hidden;
  max-width: none;
  .title {
    color: #2a2a2a;
    width: 100%;
    max-width: 1536px;
    text-align: left;
    padding: 0 16px;
    margin-bottom: 0;
  }
  .testimonial-swiper {
    width: 100%;
    height: 100%;
    margin-bottom: 16px;
    padding-top: 24px;
    padding-bottom: 32px;
    overflow: visible;
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      padding: 32px 16px;
      border-radius: 8px;
      overflow: hidden;
      filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.1));
      .testimonial-card {
        position: relative;
        align-items: center;
        justify-content: space-between;
        gap: 33px;
        .content {
          position: relative;
          p {
            color: #000;
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 27px */
            letter-spacing: -0.5px;
            padding: 0 24px;
          }
          > img {
            position: absolute;
            height: 24px;
            width: auto;
          }
          .open-quote {
            left: 0px;
            top: 0px;
          }
          .close-quote {
            right: 0px;
            bottom: 0px;
          }
        }
        .description {
          margin-top: 24px;
          gap: 12px;
          .avatar {
            height: 40px;
            aspect-ratio: 1;
            border-radius: 40px;
            background-color: #d9d9d9;
            overflow: hidden;
            justify-content: center;
            img {
              flex-shrink: 0;
              min-width: 100%;
              min-height: 100%;
              object-fit: cover;
            }
          }
          .info {
            flex-grow: 1;
            align-items: start;
            .name {
              color: #000;
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              letter-spacing: -0.3px;
            }
            .position {
              color: #616161;
              font-family: Inter;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 1.5;
              letter-spacing: -0.28px;
            }
          }
        }
      }
    }
  }
  .swiper-btn {
    width: 100%;
    max-width: 1536px;
    justify-content: center;
    gap: 16px;
    > button {
      height: 48px;
      aspect-ratio: 1;
      border-radius: 100px;
      background-color: #b69e42;
      // border: 1px solid black;
      display: flex;
      align-items: center;
      justify-content: center;
      > svg {
        color: white !important;
      }
    }
  }

  @include tablet {
    padding-top: 80px;
    padding-bottom: 80px;
    .title,
    .swiper-btn {
      padding: 0 24px;
    }
    .title {
      margin-bottom: 16px;
    }
  }

  @include desktop {
    padding-top: 120px;
    padding-bottom: 120px;
    .title {
      padding: 0 48px;
      margin-bottom: 24px;
    }
    .testimonial-swiper {
      padding-bottom: 48px;
      .swiper-slide {
        padding: 36px 51px;
        .testimonial-card {
          .content {
            p {
              font-size: 21px;
              letter-spacing: -0.8px;
            }
          }
          .description {
            margin-top: 16px;
            .avatar {
              height: 64px;
            }
            .info {
              .name {
                font-size: 16px;
              }
              .position {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    .swiper-btn {
      justify-content: flex-end;
    }
  }
}
