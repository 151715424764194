@import "../../../../../../styles/variables.scss";

//Default theme
.profile .hero-left-container.default {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  .hero-left-content {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 24px;
    .image {
      width: 100%;
      aspect-ratio: 16/9;
      overflow: hidden;
    }
    .info {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 100%;
      gap: 8px;
      .title {
        color: #151414;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -1.5px;
        margin-bottom: 0;
      }
      .info-descriptions {
        display: flex;
        flex-direction: column;
        gap: 24px;
        .description {
          color: #616161;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 1.5;
          letter-spacing: -0.3px;
        }
      }
    }
  }
  @include tablet {
    .hero-left-content {
      gap: 32px;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      .image {
        width: 100%;
        aspect-ratio: 16/9;
        overflow: hidden;
      }
      .info {
        width: 100%;
        gap: 16px;
        .title {
        }
        .info-descriptions {
          .description {
            font-size: 21px;
          }
        }
      }
    }
  }
  @include desktop {
    .hero-left-content {
      gap: 100px;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;
      padding: 0;
      padding-left: 48px;
      .image {
        width: 100%;
        aspect-ratio: 0.89;
        max-width: 600px;
      }
      .info {
        .title {
          font-size: 64px;
          letter-spacing: -2px;
        }
      }
    }
  }
}
