@import "../../../../../../styles/variables.scss";

.profile .client-container.other {
  width: 100%;
  // background-color: #d8d8d8;

  .client-image {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 100%;
    }
  }
  .client-content {
    width: 100%;
  }
  .sub-title {
    color: #616161;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 1.2px;
    margin-bottom: 8px;
  }
  .title {
    text-align: center;
  }
  .client-swiper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      .client-image {
        aspect-ratio: 1;
        overflow: hidden;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  @include tablet {
    .sub-title {
      font-size: 14px;
    }
    .client-swiper {
      padding-top: 36px;
    }
  }
  @include desktop {
    .title {
      margin-bottom: 36px;
    }
    .sub-title {
      font-size: 18px;
      letter-spacing: -0.5;
    }
  }
  @include large-desktop {
    .title {
      margin-bottom: 36px;
    }
    .sub-title {
      font-size: 18px;
      letter-spacing: -0.5;
    }
  }
}
