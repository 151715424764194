@import "../../../../../../styles/variables.scss";

//Standard themme
.profile .custom-three-container.standard {
  width: 100%;
  .custom-three-content {
    padding-top: 64px;
    padding-bottom: 64px;
    gap: 56px;
    > .title {
      align-self: flex-start;
    }
    .custom-items {
      display: grid;
      grid-template-columns: 1fr;
      align-items: flex-start;
      row-gap: 48px;
      column-gap: 32px;
      .custom-item {
        width: 100%;
        gap: 16px;
        align-items: flex-start;
        .item-info {
          align-items: flex-start;
          width: 100%;
          gap: 16px;
          .title {
            color: #151414;
            font-family: Inter;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -1px;
            text-align: left;
            margin-bottom: 0;
          }
          .description {
            color: #000;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.5px;
            text-align: left;
          }
        }
        .image {
          width: 100%;
          height: 100%;
          overflow: hidden;
          aspect-ratio: 1;
        }
      }
      // .custom-item:nth-child(2) {
      //   flex-direction: row-reverse;
      // }
    }
    @include tablet {
      .custom-items {
        grid-template-columns: 1fr 1fr;
      }
    }
    @include desktop {
      padding-top: 120px;
      padding-bottom: 120px;
      .custom-items {
        grid-template-columns: 1fr 1fr;
        .custom-item {
          gap: 32px;
          flex-wrap: nowrap;
          align-items: center;
          .image {
            max-width: 350px;
          }
        }
      }
    }
  }
}
